import React, {useState, useEffect} from 'react'

import LocalizedStrings from 'react-localization';
import splash_img from '../assets/splash_img.svg'
import music_notes_about_group from '../assets/music_notes_about_group.svg'
import planet_about from '../assets/planet_about.svg'
import music_note_about from '../assets/music_note_about.png'
import music_note_2 from '../assets/music_note_2.png'
import music_note_3 from '../assets/music_note_3.png'
import music_note_4 from '../assets/music_note_4.png'
import music_note_5 from '../assets/music_note_5.png'

import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


let localization = new LocalizedStrings({
  en:{
    title:"History of IMA",
    text:"The notes appeared when a tiny cluster of cosmic energy exploded in a remote nook of one galaxy. Lost in the infinite space, the notes are now flying among the cosmic dust expecting someone to find and unite them in one system.",
    subText:" A space mission that is about to launch its research of a new phenomenon and fully dive into learning a new science is looking for young and grownup researchers.",
    call:"To become a part of a great adventure into the music world:",
    button:"How will it work?", 
  },
  ukr: {
    title:"Історія ІМА",
    text:"Нотки зародились ще тоді, коли маленький згусток космічної енергії вчинив у закутку одної з галактик великий вибух. Загублені в безмежному просторі, зараз нотки літають поміж космічного пилу та чекають дня, поки хтось відшукає їх та обʼєднає в єдину систему. ",
    subText:"В космічну експедицію, що ось-ось займеться дослідженням нового явища та повністю порине у вивчення нової науки - музики, потрібні маленькі та дорослі дослідники. ",
    call:"Щоб стати частинкою великої подорожі в світ музики:",
    button:"Подати заявку", 
  }
 })

 const scrollToElement = (element_name) => {
  scroller.scrollTo(element_name, {
    duration: 800,
    smooth: true,
  })
}


const About = ({ukrSelected}) => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  ukrSelected ? 
    localization.setLanguage('ukr')
  :
    localization.setLanguage('en')

  return (
    <>
      <section id="about-element">

      <div className='about-container-wrap'>
        <div className='about-container'>
          <div className='about-title'>
            <p>{localization.title}</p>
          </div>
          <div className='about-main-text'>
            <p>{localization.text}</p>
          </div>
          <div className='about-secondary-text'>
            <p>{localization.subText}</p>
          </div>
          <div className='about-bottom-text'>
            <p>{localization.call}</p>
          </div>
          <div className='about-button-wrap'>
            <div onClick={() => scrollToElement('applicationElement')} className='about-button'>
              <p>{localization.button}</p>
            </div>
            <div className='about-button-shadow'></div>
          </div>
        </div>

        <div className='notes-container'>
        <div>
            <img src={planet_about} className="planet-about" style={{marginTop: `-${200-scrollY*0.1}px`, transform: `rotate(${scrollY*0.05}deg)`}}/>
            <img src={splash_img} className='img-splash desktopVersion' style={{marginTop: '50px'}}/>
            <img src={splash_img} className='img-splash mobileVersion' style={{marginTop: '300px', height: '350px'}}/>
            <img src={music_note_about} className='music-note-about-dynamic' style={{height: '60px', marginTop: `${300-scrollY*0.1}px`, marginLeft: `${200-scrollY*0.02}px`, transform: `rotate(${scrollY*0.03}deg)`}}/>
            
            <img src={music_note_2} className='music-note-about-dynamic2' style={{height: '70px', marginTop: `${400-scrollY*0.1}px`, marginLeft: `${300-scrollY*0.04}px`, transform: `rotate(-${scrollY*0.03}deg)`}}/>
            <img src={music_note_3} className='music-note-about-dynamic2' style={{height: '60px', marginTop: `${550+scrollY*0.1}px`, marginLeft: `${200-scrollY*-0.02}px`, transform: `rotate(${scrollY*0.01}deg)`}}/>
            <img src={music_note_4} className='music-note-about-dynamic2' style={{height: '40px', marginTop: `${800-scrollY*0.1}px`, marginLeft: `${340-scrollY*-0.02}px`, transform: `rotate(${scrollY*0.01}deg)`}}/>
            <img src={music_note_5} className='music-note-about-dynamic2' style={{height: '40px',marginTop: `${300-scrollY*0.1}px`, marginLeft: `${400-scrollY*-0.05}px`, transform: `rotate(${scrollY*0.01}deg)`}}/>
            
            <img src={music_notes_about_group} className='notes-group-about desktopVersion' style={{marginTop: '250px'}}/>
            <img src={music_notes_about_group} className='notes-group-about mobileVersion' style={{marginTop: '250px'}}/>

          </div>
        </div>
      </div>
      </section>
    </>
  )
}

export default About