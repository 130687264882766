import React, {useEffect, useState} from 'react'

import LocalizedStrings from 'react-localization';

import File_download from '../assets/file_download.svg'
import green_planet from '../assets/green_planet.png'
import stone_apply from '../assets/stone_apply.svg'
import rocks_svg2 from '../assets/rocks_svg2.svg'
import rocks_svg3 from '../assets/rocks_svg3.svg'

import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import ima_price_eng from '../assets/IMA Virtuosos info.pdf'
import ima_price_ua from '../assets/IMA Virtuosos інформація.pdf'

let localization = new LocalizedStrings({
  en:{
    mainTitle:"How to participate?",
    requirements:"To participate, you need to get acquainted with all the terms and conditions of this event. The next step is to fill in the application and pass the selection process. Once you submit the application, our manager will keep in touch to help you with the following steps and for informational support.",
    button:"Apply",
    warningTitle:"What you need to know ",
    warning:"Young musicians aged 6 to 24 are encouraged to participate in the academy. Anyone can participate in the “free attendance program”. In addition to the learning process, the organizers provide all the participants with the room for musical instrument lessons and take care of the entertainment program. To find out more about the cost, scedule and other details, please download this document.",
    fileDownload:"Learn more",
    file_language: ima_price_eng,
  },
  ukr: {
    mainTitle:"Як стати учасником",
    requirements:"Щоб стати учасником, потрібно ознайомитись з усіма умовами проведення заходу, після цього - заповнити заявку та пройти конкурсний відбір. З моменту подачі заявки на звʼязку з вами буде наш менеджер, який допоможе з подальшими кроками та буде підтримувати вас інформаційно.",
    button:"Подати заявку",
    warningTitle:"Що варто знати?",
    warning:"До участі в академії заохочуються молоді музиканти, віком від 6 до 24 років. Слухачами та учасниками вільної програми можуть стати всі охочі. Організатори академії, окрім навчального процесу, забезпечують всіх учасників місцем для занять на музичних інструментах та дбають про культурно-розважальну програму заходу. Більше про розклад, вартість та деталі дізнавайтесь, завантаживши документ:",
    fileDownload:"Дізнатись всі деталі",
    file_language: ima_price_ua,
  }
 })

 const scrollToElement = (element_name) => {
  scroller.scrollTo(element_name, {
    duration: 800,
    smooth: true,
  })

 }


const HowToApply = ({ukrSelected}) => {

  ukrSelected ? 
    localization.setLanguage('ukr')
  :
    localization.setLanguage('en')
    const [scrollY, setScrollY] = useState(0);

    
    useEffect(() => {
      const handleScroll = () => {
        setScrollY(window.scrollY);
      };
      handleScroll();
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  return (
    <>
    <div className='desktopVersion'>
      <img src={green_planet} className="green-planet-absolute" style={{transform: `rotate(${scrollY*0.05}deg)`, marginRight: `${-scrollY*0.02}px`}}/>
      <img src={stone_apply} className="stone-about-absolute" style={{transform: `rotate(${170-scrollY*0.05}deg)`}}/>
      <img src={rocks_svg2} className="stones2-about-absolute" style={{transform: `rotate(${70-scrollY*0.02}deg)`, marginLeft: `${20+scrollY*0.05}px`}}/>
      <img src={rocks_svg3} className="stones2-about-absolute" style={{transform: `rotate(${170-scrollY*0.01}deg)`, marginTop: `${100+scrollY*-0.05}px`}}/>
    </div>
    <div className='mobileVersion' style={{marginTop: '100px'}}>
      {/* <img src={green_planet} className="green-planet-absolute" style={{transform: `rotate(${scrollY*0.1}deg)`}}/>   */}
      <img src={stone_apply} className="stone-about-absolute" style={{transform: `rotate(${170-scrollY*0.05}deg)`}}/>
      <img src={rocks_svg2} className="stones2-about-absolute" style={{transform: `rotate(${70-scrollY*0.02}deg)`, marginLeft: `${120+scrollY*0.01}px`}}/>
      <img src={rocks_svg3} className="stones2-about-absolute" style={{transform: `rotate(${170-scrollY*0.01}deg)`, marginLeft: `${20+scrollY*0.01}px`}}/>
    
    </div>
    
    <section id="application-element">

      <div className='apply-container-wrap'>
        
        <div className='apply-container'>
          <div className='apply-member'>
            <div className='apply-member-title'>
              <p>{localization.mainTitle}</p>
            </div>
            <div className='apply-member-text'>
              <p>{localization.requirements}</p>
            </div>
            <div className='apply-button-wrap'>
              <div onClick={() => scrollToElement('applicationElement')} className='apply-member-button'>
                <p>{localization.button}</p>
              </div>
              <div className='apply-button-shadow'></div>
            </div>
          </div>

          <div className='apply-warning' >
            <div className='apply-warning-title'>
              <p>{localization.warningTitle}</p>
            </div>
            <div className='apply-warning-text'>
              <p>{localization.warning}</p>
            </div>
            <a className='apply-warning-file' style={{zIndex: '1000'}} download href={localization.file_language}>
                <img src={File_download}></img>
              <p>{localization.fileDownload}</p>
            </a>
          </div>
        </div>
      </div>
      </section>
    </>
  )
}

export default HowToApply