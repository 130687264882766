import React from 'react'

import LocalizedStrings from 'react-localization';
import Music_note from '../assets/music_note_for_title.svg'



const TeamMember = ({person}) => {

  let instrument
  let bio 
  let name
  if(person.ukr === true)
    {
      instrument = person.instrument_ukr
      bio = person.bio_ukr
      name = person.name_ukr
    }
  else
    {
      instrument = person.instrument_en
      bio = person.bio_en
      name = person.name_en
    }

  return (
    <>
        <div className='team-member-wrap'>
                <div className='team-member'>
                    <img className='team-member-photo' src={person.photo} style={{objectFit: 'cover', borderRadius: '1000px'}}></img>
                    <div className='team-member-description'>
                        <div className='team-member-description-top'>
                          <div className='musical-instrument'>
                            <img src={Music_note}></img>
                            <p>{instrument}</p>
                          </div>
                          <p className='team-member-name'>{name}</p>
                        </div>
                        <p className='team-member-bio'>{bio}</p>
                    </div>
                </div>
              </div>

    </>
  )
}

export default TeamMember