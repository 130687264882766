import './styles/start.css'; 
import './styles/about.css'; 
import './styles/schedule.css'; 
import './styles/teachers.css'; 
import './styles/howToApply.css'; 
import './styles/application.css'; 
import './styles/contacts.css'; 
import './styles/bottomer.css'; 
import './styles/animation.css'; 
import './styles/header.css'; 
import './styles/dynamic.css'; 

import {useState, useEffect} from 'react'

import MenuMobile from './components/MenuMobile.jsx'
import Header from './components/Header.jsx'
import Start from './components/Start.jsx'
import About from './components/About.jsx'
import Schedule from './components/Schedule.jsx'
import Teachers from './components/Teachers.jsx'
import HowToApply from './components/HowToApply.jsx'
import Application from './components/Application.jsx'
import Contacts from './components/Contacts.jsx'
import Bottomer from './components/Bottomer.jsx'
import HomePage from "./Home"
import UserAgreement_ukr from './components/documents/UserAgreement_ukr';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import UserAgreement_eng from './components/documents/UserAgreement_eng';
import Privacy_eng from './components/documents/Privacy_eng';
import Privacy_ukr from './components/documents/Privacy_ukr';
// import Background from './assets/background.svg'
// import background_image from './assets/background.svg'


function App() {
  let isukr 
  let [ukrSelected, setUkrSelected] = useState(true)
  const toggleLanguage = () => {
    setUkrSelected(!ukrSelected)
    isukr = ukrSelected
  }

  return (
    <div className="App">
       <Router>
          <Routes>  
            <Route path="/">
              <Route index element={<HomePage/>}/>
              <Route path="user_agreement_ukr/" element={<UserAgreement_ukr/>}/> 
              <Route path="user_agreement_eng/" element={<UserAgreement_eng/>}/>  
              <Route path="privacy_ukr/" element={<Privacy_ukr/>}/>  
              <Route path="privacy_eng/" element={<Privacy_eng/>}/>  

            </Route>
          </Routes>
        </Router>
    </div>
  );
}

export default App;
