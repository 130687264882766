import { nominalTypeHack } from 'prop-types';
import React, {useState, useEffect} from 'react'
import { useForm, Controller } from "react-hook-form";

import LocalizedStrings from 'react-localization';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import application_svg from '../assets/application_svg.svg'
import dynamic_meteor_form from '../assets/dynamic_meteor_form.png'

import policy_ukr from '../assets/Політика конфіденційності та захист персональних даних_укр.docx'
import policy_eng from '../assets/Політика конфіденційності та захист персональних даних_англ.docx'

import user_ukr from '../assets/Угода користувача_укр..docx'
import user_eng from '../assets/Угода користувача_eng.docx'



let localization = new LocalizedStrings({
  en:{
    title:"Apply",
    personName:"Name",
    email:"Email",
    phone:"Phone",
    consent:"I give permission for the processing of my personal data",
    button:"Participate",
    policy:"Privacy policy",
    user_agreement: "User agreement",
    fieldWarnig:"This field is required",
    success:"Your application has been successfully registered!",
    policy_file: "https://imavirtuosos.com/privacy_eng",
    user_agreement_file: "https://imavirtuosos.com/user_agreement_eng"

  },
  ukr: {
    title:"Подати заявку",
    personName:"Ім'я",
    email:"Пошта",
    phone:"Телефон",
    consent:"Я даю згоду на обробку моїх персональних даних",
    button:"Стати учасником",
    policy:"Політика конфіденційності",
    user_agreement: "Угода користувача",
    fieldWarnig:"Це поле обов'язкове",
    success:"Вашу заявку успішно зареєстровано!",
    policy_file: "https://imavirtuosos.com/privacy_ukr",
    user_agreement_file: "https://imavirtuosos.com/user_agreement_ukr"
  }
 })

 
 
const defaultValues = { 
  'name': null,
  'email': null,
  'phone': null,
}

const Application = ({ukrSelected}) => {
  const { register, handleSubmit, watch, formState: { errors }, reset, control } = useForm({defaultValues})

  const [scrollY, setScrollY] = useState(0);
  ukrSelected ? 
    localization.setLanguage('ukr')
  :
    localization.setLanguage('en')
    useEffect(() => {
      const handleScroll = () => {
        setScrollY(window.scrollY);
      };
      handleScroll();
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const sendForm = async (formData) => {

      let response = await fetch('https://ima-back.herokuapp.com/api/add-application/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              'name': formData.name,
              'email': formData.email,
              'phone': formData.phone,
            })
        })
      let data = response.json()
      console.log(data)
      if(formData.name !== null && formData.phone !== null && formData.email !== null && formData.personal_data === true){
        reset()
        alert(localization.success);
      } 
    }
  return (
    
    <>
       <img src={dynamic_meteor_form} className="music-meteor-application-dynamic desktopVersion" style={{marginTop: `${-100+scrollY*0.05}px`, marginLeft: `${400-scrollY*0.05}px`}}/>

        <img className='application-svg-group' src={application_svg}/>
      <section id="next-element">
      <div className='application-wrap'>
        <div className='application'>
          <div className='application-title'>
            <p>{localization.title}</p>
          </div>

          <form className='application-form' onSubmit={handleSubmit(sendForm)}>
                        
            <input className='application-form-input' placeholder={localization.personName} type='text' {...register("name", { required: true })} />
            {errors.name && <span className='field-warning'>{localization.fieldWarnig}</span>}
                        
                        
            <input className='application-form-input' placeholder={localization.email} type='email' {...register("email", { required: true })} />
            {errors.email && <span className='field-warning'>{localization.fieldWarnig}</span>}

            <Controller
              control={control}
              name="phone"
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <PhoneInput
                  {...field}
                  placeholder={localization.phone}
                  inputExtraProps={{
                    ref,
                    required: true,
                    autoFocus: true
                  }}
                  country={"ua"}
                  countryCodeEditable={true}

                  containerClass="container-class"
                  inputClass="input-class"
                  containerStyle={{
                    
                    display: 'flex',
                    justifyContent: 'center',

                    height: '71px',
                    zIndex: '20',
                    
                  }}

                  inputStyle={{
                    border: '1px solid #69F5FF',
                    backgroundColor: '#0B0426',
                    color: '#FFFFFF',
                    
               
                    height: '71px',
                    width: '434px',
                    borderRadius: '40px',
                    paddingLeft: '60px'
                  }}
                  buttonStyle={{
                    border: '1px solid #69F5FF',
                    backgroundColor: '#0B0426',

                    
                    borderRadius: ' 40px 0px 0px 40px',
                    borderRight: 'none',
                    paddingLeft: '10px',
                    marginLeft: '-374px',
                  }}
                  dropdownStyle={{
                    backgroundColor: '#0B0426',
                    color: '#FFFFFF',
                    zIndex: '101',
                    fontFamily: 'Mulish',
                    height: '400px'
                  }}
                />
                
              )}
            />
            {errors.phone && <span className='field-warning'>{localization.fieldWarnig}</span>}
            
            <label className='application-form-lable'>
              <input className='application-form-checkbox' type='checkbox' {...register("personal_data", { required: true })} />
              <p className='application-form-checkbox-text'>{localization.consent}</p>
            </label>
            {errors.personal_data && <span className='field-warning-checkbox'>{localization.fieldWarnig}</span>}

            <div className='application-button-wrap'>  
              <input className='application-form-submit'type="submit" onClick={sendForm} value={localization.button}/>
              <div className='application-button-shadow'></div>
            </div>     
         
            
            {/* <div className='button-filter-application'></div> */}
            <div className='application-confidentiality-policy' style={{paddingBottom: '40px'}}><a href={localization.policy_file}>{localization.policy}</a><br/><a href={localization.user_agreement_file}>{localization.user_agreement}</a></div>
          </form>
          
        </div>
      </div>
      </section>
    </>
  )
}

export default Application