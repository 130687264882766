import React from 'react'

const Privacy_ukr = () => {
  return (
    <div>
      <p><strong>ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ ТА ЗАХИСТУ ПЕРСОНАЛЬНИХ ДАНИХ<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p>&nbsp;</p>
<ol>
<li><strong>ВИЗНАЧЕННЯ ПОНЯТТІВ</strong></li>
<ol>
<li><strong>"Закон"</strong> означає Федеральний Закон Швейцарії &laquo;Про захист даних&raquo; з усіма змінами та доповненнями.</li>
<li><strong>"Сайт"</strong> означає інформаційну одиницю в Інтернеті, ресурс із веб-сторінок (документів), які об'єднані спільною темою та пов'язані між один з одним за допомогою посилань. Сайт зареєстрований на Правовласника та обов'язково прив'язаний до конкретного домену, що є його адресою. Ця Політика розроблена для наступного сайту: <a href="https://www.imavirtuosos.com/">https://www.imavirtuosos.com/</a>.</li>
<li><strong>"Персональні дані"</strong> означає сукупність особистих даних та/або неперсоніфікованої інформації про Користувача, що надається Правовласнику в результаті використання Користувачем Сайту та/або автоматично збирається Правовласником та/або третіми особами.</li>
<li><strong>"Політика"</strong> означає цю Політику конфіденційності та захисту персональних даних для Сайту (з усіма наявними доповненнями та змінами).</li>
<li><strong>"Користувач"</strong> означає юридичну або фізичну особу, яка користується Сайтом та/або отримала до неї доступ з будь-якого пристрою.</li>
<li><strong>"Угода користувача"</strong> означає угоду, що укладається між Правовласником та Користувачем щодо порядку, правил та особливостей використання Сайту. Користувач приєднується до такої угоди і не має права вносити та/або вимагати внесення до такої угоди будь-яких змін чи доповнень.</li>
<li><strong>"Правовласник"</strong> означає особу, на ім'я якої зареєстровано Сайт, а саме <strong>Dzialak &amp; S&ouml;hne Musik-Akademie, Inh. J. Dzialak</strong>, код Компанії CHE-116.166.121 від 03.11.2010 року, юридична адреса: 8180, Швейцарія, м. Бюлах (B&uuml;lach), вул. Im Baumgarten, буд. 8.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li><strong>ВІДНОСИНИ, НА ЯКІ ПОШИРЮЄТЬСЯ ПОЛІТИКА</strong></li>
<ol>
<li>Ця Політика використовується і застосовується виключно до Персональних даних, які отримуються від Користувача у зв'язку з використанням ним Сайту. Положення цієї Політики спрямовані на:</li>
</ol>
</ol>
<ul>
<li>визначення видів та типів одержуваних Персональних даних, напрямків та цілей використання (обробки) Персональних даних, а також джерел отримання таких Персональних даних;</li>
<li>визначення прав Користувача щодо захисту конфіденційності переданих Користувачем Персональних даних;<span class="Apple-converted-space">&nbsp;</span></li>
<li>визначення осіб, відповідальних за обробку та зберігання Персональних даних, а також третіх осіб, яким такі дані розкриваються (повністю або частково).</li>
</ul>
<ol start="2">
<ol start="2">
<li>Правила цієї Політики не застосовуються у разі обробки третіми особами Персональних даних, які добровільно надаються Користувачем.</li>
<li>За допомогою використання Сайту Користувач погоджується з умовами цієї Політики та дає свою згоду Правовласнику на збирання, обробку, утримання та зберігання Персональних даних у порядку та на умовах, передбачених цією Політикою.</li>
<li>Якщо Користувач не погоджується з умовами цієї Політики та/або окремими умовами цієї Політики, у такому разі Користувач зобов'язаний негайно припинити використання Сайту.</li>
<li>У зв'язку з наданням Персональних даних Користувач автоматично отримує такі права:</li>
</ol>
</ol>
<ul>
<li>одержувати дані щодо їх обробки (підстави та цілі такої обробки, застосовувані способи обробки, відомості про осіб, які мають доступ до них або яким вони можуть бути розкриті на підставі договору або Закону).</li>
<li>отримувати дані про місцезнаходження та ідентифікаційні дані осіб, які здійснюють обробку Персональних даних.</li>
<li>отримувати дані про термін зберігання персональних даних.</li>
<li>отримувати дані про здійснену або про передбачувану транскордонну передачу Персональних даних.</li>
<li>оскаржити дії чи бездіяльність Правовласника до уповноваженого органу захисту прав суб'єктів персональних даних або в судовому порядку.</li>
<li>отримувати відшкодування збитків та/або компенсацію моральної шкоди в судовому порядку внаслідок допущених Правовласником та/або третіми особами порушень прав Користувача на охорону та захист його Персональних даних.</li>
<li>реалізовувати інші права у сфері захисту Персональних даних, передбачені Законом, іншими нормативно-правовими актами чи положеннями цієї Політики.</li>
</ul>
<p>&nbsp;</p>
<ol start="3">
<li><strong>ПЕРЕЛІК ПЕРСОНАЛЬНИХ ДАНИХ, ЩО ЗБИРАЮТЬСЯ</strong></li>
<ol>
<li>У зв'язку з використанням Сайту Правовласник може автоматично збирати та обробляти наступну неперсоніфіковану інформацію про Користувача:</li>
</ol>
</ol>
<ul>
<li>інформацію про трафік, можливу кількість скоєних кліків, логи та інші дані,</li>
<li>інформацію про пристрій (ідентифікаційний номер пристрою, з якого виконується вхід, операційна система, платформа, тип браузера та інша інформація про браузер, адресу IP),</li>
<li>особисті дані про Користувача.</li>
</ul>
<ol start="3">
<ol start="2">
<li>Користувач надає правовласнику про себе наступні особисті дані:</li>
</ol>
</ol>
<ul>
<li>повні прізвище, ім'я та по батькові</li>
<li>дату народження та вік</li>
<li>адреса місця постійного проживання</li>
<li>адреса електронної пошти</li>
<li>номер мобільного телефону</li>
<li>громадянство Користувача</li>
<li>дані та інформація, що отримується в результаті об'єднання тих чи інших Персональних даних конкретного Користувача, а також дані та інформація, що одержуються від третіх осіб (партнерів, маркетологів, дослідників).</li>
</ul>
<ol start="3">
<ol start="3">
<li>Користувач є єдиною відповідальною особою за повноту Персональних даних, що надаються, і зобов'язаний здійснювати своєчасну їх зміну (оновлення, перевірку, коригування) на регулярній основі.</li>
<li>Правовласник виходить з того, що всі надані Користувачем Персональні дані є достовірними, і що Користувач підтримує таку інформацію в актуальному стані.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="4">
<li><strong>ЦІЛІ ЗБОРУ ТА ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ</strong></li>
<ol>
<li>Збір та обробка Персональних даних здійснюється в таких цілях:</li>
</ol>
</ol>
<ul>
<li>для аналізу поведінки Користувача, а також виявлення переваг Користувача до певного виду контенту,</li>
<li>для оперативної та коректної роботи Сайту, поліпшення його функціональності та контенту,</li>
<li>для ідентифікації Користувача,</li>
<li>на дотримання вимог Закону,</li>
<li>для технічної підтримки Сайту, виявлення проблем у його роботі та їх усунення,</li>
<li>підтримки зв'язку з Користувачем (комунікація),</li>
<li>для виконання інших зобов'язань Правовласника, які постали перед Користувачем,</li>
<li>щодо статистичних досліджень,</li>
<li>для будь-яких інших цілей за умови отримання окремої згоди від Користувача.</li>
</ul>
<ol start="4">
<ol start="2">
<li>Обробка Персональних даних складає основі принципів:<span class="Apple-converted-space">&nbsp;</span></li>
</ol>
</ol>
<ul>
<li>законності цілей і способів обробки,</li>
<li>сумлінності,</li>
<li>відповідності цілей обробки Персональних даних цілям, заздалегідь визначеним та заявленим при зборі таких Персональних даних,<span class="Apple-converted-space">&nbsp;</span></li>
<li>відповідності обсягу та характеру оброблюваних Персональних даних заявленим цілям їх обробки.</li>
</ul>
<ol start="4">
<ol start="3">
<li>Обробка Персональних даних проводиться у випадках:<span class="Apple-converted-space">&nbsp;</span></li>
</ol>
</ol>
<ul>
<li>отримання згоди від Користувача (в тому числі, шляхом заповнення реєстраційної форми на Сайті &ndash; &laquo;Подати заявку&raquo;),<span class="Apple-converted-space">&nbsp;</span></li>
<li>досягнення Правовласником цілей, передбачених міжнародним договором, чинним законодавством чи Законом,<span class="Apple-converted-space">&nbsp;</span></li>
<li>надання Користувачем своїх Персональних даних необмеженому колу особі,</li>
<li>виконання інших зобов'язань Правовласника перед Користувачем, включаючи, однак не обмежуючись, надання певного контенту Користувачеві,</li>
<li>порятунку життя або здоров'я Користувача, коли згоду на обробку його Персональних даних не вдається отримати завчасно.</li>
</ul>
<ol start="4">
<ol start="4">
<li>У разі знеособлення Персональних даних, що не дозволяє прямо або опосередковано визначити Користувача, подальше використання та розкриття таких Персональних даних третім особам допускається і щодо таких осіб не застосовуються правила цієї Політики.</li>
<li>Правовласник приймає всі можливі заходи для захисту конфіденційності отриманих Персональних даних, за винятком випадків, коли Користувач зробив такі дані загальнодоступними.</li>
<li>Обробка Персональних даних здійснюється з використанням засобів автоматизації та без використання таких засобів автоматизації.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="5">
<li><strong>ДОСТУП ТРЕТІХ ОСІБ ДО ПЕРСОНАЛЬНИХ ДАНИХ</strong></li>
<ol>
<li>Правовласник має право розкривати Персональні дані наступним особам:</li>
</ol>
</ol>
<ul>
<li>своїм афілійованим особам, філіям та представництвам, відкритим як на території України, так і на території інших держав,</li>
<li>правонаступникам Правовласника, які виникли внаслідок його ліквідації, реорганізації чи банкрутства, та які отримали виняткові права володіння Сайтом,</li>
<li>третім особам виключно з метою надання отримання Користувачем певного контенту або доступу до такого контенту,</li>
<li>третім особам, коли Користувач дав згоду на розкриття, передачу або обробку своїх Персональних даних, а також в інших випадках, прямо передбачених чинним законодавством, Законом або цією Політикою.</li>
</ul>
<ol start="5">
<ol start="2">
<li>Правовласник розкриває Персональні дані тільки в тому випадку, якщо:<span class="Apple-converted-space">&nbsp;</span></li>
</ol>
</ol>
<ul>
<li>впевнений, що треті особи дотримуватимуться умов цієї Політики та вживатимуть таких самих заходів щодо захисту конфіденційності Персональних даних, які вживає безпосередньо Правовласник,<span class="Apple-converted-space">&nbsp;</span></li>
<li>згоду на таке розкриття було попередньо висловлено Користувачем та/або допускається на підставі чинного законодавства та/або Закону.</li>
</ul>
<p>&nbsp;</p>
<ol start="6">
<li><strong>ПОРЯДОК ПОДАЧІ СКАРГ І ЗАПИТАНЬ ПРАВООВЛАСНИКУ</strong></li>
<ol>
<li>Кожен Користувач має право висловити своє заперечення Правовласнику проти обробки та зберігання його Персональних даних, шляхом надіслання такого запиту на електронну пошту <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>.</li>
<li>Якщо у Користувача виникають питання, пов'язані з порядком застосування або використання цієї Політики, порядком та/або способом обробки Персональних даних, Користувач може поставити таке запитання надіславши на електронну пошту: <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>.</li>
<li>Користувач має право в будь-який момент змінити або видалити Персональні дані шляхом надсилання спеціального запиту Правовласнику на електронну пошту: <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>.</li>
<li>Правовласник має право відмовити у зміні або видаленні Персональних даних, якщо такі дії призведуть:</li>
</ol>
</ol>
<ul>
<li>до порушення правил цієї Політики,</li>
<li>до порушення чинного законодавство та/або Закону,</li>
<li>характер Персональних даних є доказом у будь-якому судовому процесі, що виник між Правовласником та Користувачем.</li>
</ul>
<p>&nbsp;</p>
<ol start="7">
<li><strong>СТРОКИ І ПОРЯДОК ЗБЕРІГАННЯ ПЕРСОНАЛЬНИХ ДАНИХ</strong></li>
<ol>
<li>Зберігання Персональних даних здійснюється самостійно Правовласником.</li>
<li>Зберігання здійснюється протягом всього терміну, необхідного для досягнення заявлених цілей обробки Персональних даних.</li>
<li>Правовласник зобов'язується негайно після досягнення цілей обробки Персональних даних знищити такі дані або знеособити.</li>
<li>Використання Сайту призначене для осіб будь-якого віку, які отримують до нього доступ лише за умови надання попередньої згоди на обробку Персональних даних таких осіб.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="8">
<li><strong>ПОРЯДОК ЗАХИСТУ ПЕРСОНАЛЬНИХ ДАНИХ</strong></li>
<ol>
<li>Захист конфіденційності Персональних даних є першорядним та важливим завданням для Правовласника. Правовласник дотримується всіх необхідних міжнародних стандартів, правил та рекомендацій щодо захисту Персональних даних.</li>
<li>Правовласник впровадив ряд технічних та організаційних методів, спрямованих на захист Персональних даних від розголошення та/або несанкціонованого доступу до таких Персональних даних третіх осіб.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="9">
<li><strong>ЗАКЛЮЧНІ ПОЛОЖЕННЯ</strong></li>
<ol>
<li>Користувачі можуть ознайомитися з умовами цієї Політики за наступним посиланням: <a href="https://imavirtuosos.com/privacy_ukr">https://imavirtuosos.com/privacy_ukr</a>.</li>
<li>Ця Політика була складена також англійською мовою, для тих Користувачів, які бажають ознайомитися із Політикою, але не володіють українською мовою. У разі розходження тексту оригіналу (українська мова) та його перекладу англійською мовою, переважну силу має українська мова.</li>
<li>Ця редакція Політики діє з моменту опублікування такої Політики на Сайті.</li>
<li>Ця Політика може бути змінена в односторонньому порядку за ініціативною Правовласника. Правовласник не несе жодної відповідальності перед Користувачем за зміну умов цієї Політики без дозволу та/або згоди Користувача.</li>
<li>Користувач сам зобов'язується регулярно перевіряти положення цієї Політики на предмет її можливої зміни та/або доповнення.</li>
<li>Ця Політика розроблена у відповідності до норм чинного законодавства України про захист персональних даних.</li>
<li>Незалежно від заходів, що вживаються Правовласником щодо захисту конфіденційності одержуваних Персональних даних, Користувач вважається належним чином ознайомлений з тим, що будь-яка передача Персональних даних в мережі Інтернет не може бути гарантовано безпечною, а тому Користувач здійснює таку передачу Персональних даних на власний ризик.</li>
</ol>
</ol>
    </div>
  )
}

export default Privacy_ukr