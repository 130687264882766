import React from 'react'


import LocalizedStrings from 'react-localization';

import Icon_facebook from '../assets/icon_facebook.svg'
import Icon_instagram from '../assets/icon_instagram.svg'
import Icon_mesenger from '../assets/email.svg'
import Icon_telegram from '../assets/icon_telegram.svg'
import Phone from '../assets/phone.svg'

import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

let localization = new LocalizedStrings({
  en:{
    description:"For more questions and suggestions contact us wia email. Be the first to find out the news and subscribe to our social networks.",
    button:"Book a call",
    phone:"phone",
  },
  ukr: {
    description:"Для всіх додаткових запитань та у випадку пропозицій пишіть на нашу пошту. Щоб першими дізнаватись актуальні новини, підписуйтесь на наші соцмережі",
    button:"Замовити дзвінок",
    phone:"телефон",
  }
 })

 const scrollToElement = (element_name) => {
  scroller.scrollTo(element_name, {
    duration: 800,
    smooth: true,
  })
}

const Contacts = ({ukrSelected}) => {

  ukrSelected ? 
    localization.setLanguage('ukr')
  :
    localization.setLanguage('en')

  return (
    <>
      <div className='contact-info-wrap'>
        <div className='contact-info'>
          <div className='contact-info-top'>
            <div className='contact-info-left'>
                <div className='contact-info-description'>
                  <p>{localization.description}</p>
                </div>
                  <div className='contacts-button-wrap'>
                    <div onClick={() => scrollToElement('applicationElement')} className='call-order'>
                      <img src={Phone}></img>
                      <p>{localization.button}</p>
                    </div>
                    <div className='contacts-button-shadow'></div>
                  </div>
            </div>

            <div className='social-networks-mobile'>

            <a href='mailto:info.imavirtuosos@gmail.com' target="_blank">
            <div className='social-network-item'>
              <img src={Icon_mesenger} style={{height: '25px'}}></img>
              <p>ima.virtuosos</p>
            </div>
            </a>

            <a href='https://instagram.com/ima.virtuosos' target="_blank">
            <div className='social-network-item'>
              <img src={Icon_instagram}></img>
              <p>ima.virtuosos</p>
            </div>
            </a>

            <a href='https://www.facebook.com/ima.virtuosos.summer.academy' target="_blank">
            <div className='social-network-item'>
              <img src={Icon_facebook}></img>
              <p>ima.virtuosos</p>
            </div>
            </a>

            <a href='https://t.me/anna_savytska' target="_blank">
            <div className='social-network-item'>
              <img src={Icon_telegram}></img>
              <p>+41763206012</p>
            </div>
            </a>

          </div>
          

            <div className='contact-info-right'>
                <div className='contact-info-subtitle'>
                  <p>E-mail</p>
                </div>
                <div className='contact-info-title'>
                  <p>info.imavirtuosos@gmail.com</p>
                </div>

                <div className='contact-info-subtitle'>
                  <p>{localization.phone}</p>
                </div>
                <div className='contact-info-title'>
                  <p>+41 76 320 60 12</p>
                </div>
            </div>
          </div>
          
          <div className='social-networks'>
            <a href='mailto:info.imavirtuosos@gmail.com' target="_blank">
            <div className='social-network-item'>
              <img src={Icon_mesenger} ></img>
              <p>ima.virtuosos</p>
            </div>
            </a>

            <a href='https://instagram.com/ima.virtuosos' target="_blank">
            <div className='social-network-item'>
              <img src={Icon_instagram}></img>
              <p>ima.virtuosos</p>
            </div>
            </a>

            <a href='https://www.facebook.com/ima.virtuosos.summer.academy' target="_blank">
            <div className='social-network-item'>
              <img src={Icon_facebook}></img>
              <p>ima.virtuosos</p>
            </div>
            </a>

            <a href='https://t.me/anna_savytska' target="_blank">
            <div className='social-network-item'>
              <img src={Icon_telegram}></img>
              <p>+41763206012</p>
            </div>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contacts