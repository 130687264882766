import './styles/start.css'; 
import './styles/about.css'; 
import './styles/schedule.css'; 
import './styles/teachers.css'; 
import './styles/howToApply.css'; 
import './styles/application.css'; 
import './styles/contacts.css'; 
import './styles/bottomer.css'; 
import './styles/animation.css'; 
import './styles/header.css'; 
import './styles/dynamic.css'; 

import {useState, useEffect} from 'react'

import MenuMobile from './components/MenuMobile.jsx'
import Header from './components/Header.jsx'
import Start from './components/Start.jsx'
import About from './components/About.jsx'
import Schedule from './components/Schedule.jsx'
import Teachers from './components/Teachers.jsx'
import HowToApply from './components/HowToApply.jsx'
import Application from './components/Application.jsx'
import Contacts from './components/Contacts.jsx'
import Bottomer from './components/Bottomer.jsx'
import PrivacyPolicy_ukr from './components/documents/UserAgreement_ukr';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

// import Background from './assets/background.svg'
// import background_image from './assets/background.svg'


function App() {
  let isukr 
  let [ukrSelected, setUkrSelected] = useState(true)
  const toggleLanguage = () => {
    setUkrSelected(!ukrSelected)
    isukr = ukrSelected
  }

  return (
    <div className="App" style={{background: "#0B0426", marginTop: 0}}>
      <div className="app-container">
      <div className='header-mobile'>
          <MenuMobile isukr={isukr} toggleLanguage={toggleLanguage} ukrSelected={ukrSelected}/>
        </div>
        <Element name="startElement"></Element>
        <Header isukr={isukr} toggleLanguage={toggleLanguage} ukrSelected={ukrSelected}/>
        <Start ukrSelected={ukrSelected}/>
        <Element name="aboutElement"></Element>
        <About ukrSelected={ukrSelected} />
        <Element name="scheduleElement"></Element>
        <Schedule ukrSelected={ukrSelected}/>
        <Element name="instructorsElement"></Element>
        <Teachers ukrSelected={ukrSelected}/>
        <Element name="howElement"></Element>
        <HowToApply ukrSelected={ukrSelected}/>
        <Element name="applicationElement"></Element>
        <Application ukrSelected={ukrSelected}/>
        <Contacts ukrSelected={ukrSelected}/>
        <Bottomer ukrSelected={ukrSelected}/>
      </div>
    </div>
  );
}

export default App;
