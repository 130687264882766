import React from 'react'

const Privacy_eng = () => {
  return (
    <div><p><strong>PRIVACY POLICY AND PERSONAL DATA PROTECTION</strong></p>
    <p>&nbsp;</p>
    <ol>
    <li><strong>DEFINITIONS</strong></li>
    <ol>
    <li><strong>"Law"</strong> means the Swiss Federal Data Protection Act with all changes and additions.</li>
    <li><strong>"Site"</strong> means an information unit on the Internet, a resource of web pages (documents) that are linked by a common theme and linked to each other by links. The site is registered to the Rights holder and must be linked to a specific domain that is his address. This Policy is designed for the following site: <a href="https://www.imavirtuosos.com/">https://www.imavirtuosos.com/</a>.</li>
    <li><strong>"Personal Data"</strong> means a set of personal data and/or impersonal information about the User provided to the Rights holder as a result of the User's use of the Site and/or automatically collected by the Rights holder and/or third parties.</li>
    <li><strong>"Policy"</strong> means this Privacy policy and personal data protection for the Site (with all available additions and changes).</li>
    <li><strong>"User"</strong> means a legal or natural person who uses the Site and/or accesses it from any device.</li>
    <li><strong>"User Agreement"</strong> means an agreement entered into between the Rights holder and the User regarding the procedure, rules and features of use of the Site. The User accedes to such agreement and has no right to make and / or require any changes or additions to it.</li>
    <li><strong>"Rights holder"</strong> means the person in whose name the Site is registered, namely the <strong>Dzialak &amp; S&ouml;hne Musik-Akademie, Inh. J. Dzialak, </strong>Company code: CHE-116.166.121 from 03.11.2010, legal address: 8180, Switzerland, B&uuml;lach, str. Im Baumgarten, building 8.</li>
    </ol>
    </ol>
    <p>&nbsp;</p>
    <ol start="2">
    <li><strong>RELATIONS TO WHICH POLICY APPLIES</strong></li>
    <ol>
    <li>This Policy is used and applies exclusively to Personal Data received from the User in connection with his use of the Site. The provisions of this Policy are aimed at:</li>
    </ol>
    </ol>
    <ul>
    <li>determination of types and types of received Personal Data, directions and purposes of use (processing) of Personal Data, as well as sources of obtaining such Personal Data,</li>
    <li>determination of the User's rights to protect the confidentiality of Personal Data transferred by him,</li>
    <li>identification of persons responsible for the processing and storage of Personal Data, as well as third parties to whom such data are disclosed (in whole or in part).</li>
    </ul>
    <ol start="2">
    <ol start="2">
    <li>The rules of this Policy do not apply in the case of processing by third parties of Personal Data voluntarily provided by the User.</li>
    <li>By using the Site, the User agrees to the terms of this Policy and gives his consent to the Rights holder to collect, process, retain and store Personal Data in the manner and under the conditions provided by this Policy.</li>
    <li>If the User does not agree with the terms of this Policy and/or certain terms of this Policy, then the User is obliged to immediately stop using the Site.</li>
    <li>In connection with the provision of Personal Data, the User automatically obtains the following rights:</li>
    </ol>
    </ol>
    <ul>
    <li>obtain data on their processing (grounds and purposes of such processing, applied processing methods, information on persons who have access to them or to whom they may be disclosed on the basis of a contract or the Law),</li>
    <li>receive location data and identification data of persons who process Personal Data,</li>
    <li>receive data on the period of storage of Personal Data,</li>
    <li>receive data on the actual or anticipated cross-border transfer of Personal Data,</li>
    <li>appeal against the actions or omissions of the Right Holder to the authorized body for the protection of the rights of Personal Data subjects or in court,</li>
    <li>receive compensation for damages and/or compensation for non-pecuniary damage in court as a result of violations of the User's rights to the protection and protection of his Personal Data by the Rights holder and/or third parties,</li>
    <li>exercise other rights in the field of Personal Data Protection provided by the Law, other regulations or provisions of this Policy.</li>
    </ul>
    <p>&nbsp;</p>
    <ol start="3">
    <li><strong>LIST OF PERSONAL DATA COLLECTED</strong></li>
    <ol>
    <li>In connection with the use of the Site, the Rights holder may automatically collect and process the following impersonal information about the User:</li>
    </ol>
    </ol>
    <ul>
    <li>traffic information, possible number of clicks, logs and other data,</li>
    <li>information about the device (identification number of the device from which the login is performed, operating system, platform, browser type and other information about the browser, IP address),</li>
    <li>Personal data about the User.</li>
    </ul>
    <ol start="3">
    <ol start="2">
    <li>The User provides the Rights holder with the following Personal Data:</li>
    </ol>
    </ol>
    <ul>
    <li>full surname, name and patronymic,</li>
    <li>date of birth and age,</li>
    <li>address of the place of permanent residence,</li>
    <li>email address,</li>
    <li>mobile phone number,</li>
    <li>citizenship of the User,</li>
    <li>data and information obtained as a result of combining certain Personal Data of a particular User, as well as data and information received from third parties (partners, marketers, researchers).</li>
    </ul>
    <ol start="3">
    <ol start="3">
    <li>The User is the sole person responsible for the completeness of Personal Data provided, and is obliged to make timely changes (updates, verifications, adjustments) on a regular basis.</li>
    <li>The Rights holder assumes that all Personal Data provided by the User are reliable, and that the User keeps such information up to date.</li>
    </ol>
    </ol>
    <p>&nbsp;</p>
    <ol start="4">
    <li><strong>PURPOSES OF COLLECTION AND PROCESSING OF PERSONAL DATA</strong></li>
    <ol>
    <li>Collection and processing of Personal Data is carried out for the following purposes:</li>
    </ol>
    </ol>
    <ul>
    <li>to analyze the User's behavior, as well as to identify the User's preferences for a particular type of content,</li>
    <li>for the prompt and correct operation of the Site, improving its functionality and content,</li>
    <li>to identify the User,</li>
    <li>compliance with the requirements of the Law,</li>
    <li>for technical support of the Site, identification of problems in its work and their elimination.</li>
    <li>maintaining communication with the User (communication),</li>
    <li>to fulfill other obligations of the Rights holder, which arose before the User,</li>
    <li>on statistical research,</li>
    <li>for any other purposes subject to the separate consent of the User,</li>
    </ul>
    <ol start="4">
    <ol start="2">
    <li>Processing of Personal Data is based on the principles of:</li>
    </ol>
    </ol>
    <ul>
    <li>legality of purposes and methods of processing,</li>
    <li>honesty,</li>
    <li>compliance of the purposes of Personal Data processing with the purposes defined in advance and stated when collecting such Personal Data,</li>
    <li>compliance of the volume and nature of the processed Personal Data with the stated purposes of their processing.</li>
    </ul>
    <ol start="4">
    <ol start="3">
    <li>The processing of Personal Data is carried out in the following cases:</li>
    </ol>
    </ol>
    <ul>
    <li>obtaining consent from the User (including, by filling out the registration form on the Site - "Apply"),</li>
    <li>achievement by the Rights holder of the goals stipulated by an international agreement, current legislation or the Law,</li>
    <li>providing the User with his/her Personal Data to an unlimited number of persons,</li>
    <li>fulfillment of other obligations of the Rights holder to the User, including, but not limited to, providing certain content to the User,</li>
    <li>saving the life or health of the User, when consent to the processing of his Personal Data cannot be obtained in advance.</li>
    </ul>
    <ol start="4">
    <ol start="4">
    <li>In case of depersonalization of Personal Data, which does not allow to directly or indirectly identify the User, further use and disclosure of such Personal Data to third parties is allowed and the rules of this Policy do not apply to such persons.</li>
    <li>The Right holder takes all possible measures to protect the confidentiality of Personal Data received, except when the User has made such data publicly available.</li>
    <li>Processing of Personal Data is carried out with the use of automation tools and without the use of such automation tools.</li>
    </ol>
    </ol>
    <p>&nbsp;</p>
    <ol start="5">
    <li><strong>THIRD PARTY ACCESS TO PERSONAL DATA</strong></li>
    <ol>
    <li>The Rights holder has the right to disclose Personal Data to the following persons:</li>
    </ol>
    </ol>
    <ul>
    <li>to its affiliates, branches and representative offices opened both on the territory of Ukraine and on the territory of other states,</li>
    <li>successors of the Rights holder, which arose as a result of its liquidation, reorganization or bankruptcy, and who received exclusive rights to own the Site,</li>
    <li>to third parties solely for the purpose of providing the User with certain content or access to certain content,</li>
    <li>to third parties, when the User has consented to the disclosure, transfer or processing of his Personal Data, as well as in other cases expressly provided by current legislation, by Law or this Policy.</li>
    </ul>
    <ol start="5">
    <ol start="2">
    <li>The Rights holder discloses Personal Data only if</li>
    </ol>
    </ol>
    <ul>
    <li>is confident that third parties will comply with the terms of this Policy and will take the same measures to protect the confidentiality of Personal Data as the Rights holder himself,</li>
    <li>consent to such disclosure has been previously expressed by the User and/or is permitted under the current legislation and/or Law.</li>
    </ul>
    <p>&nbsp;</p>
    <ol start="6">
    <li><strong>PROCEDURE FOR SUBMITTING COMPLAINTS AND QUESTIONS TO THE OWNER</strong></li>
    <ol>
    <li>Each User has the right to object to the Rights holder against the processing and storage of his Personal Data by sending such a request to the e-mail address <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>.</li>
    <li>If the User has any questions regarding the application or use of this Policy, the procedure and/or method of processing Personal Data, the User may ask such a question by sending it to the e-mail address <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>.</li>
    <li>The User has the right to change or delete Personal Data at any time by sending a special request to the Rights holder at the following e-mail address: <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>.</li>
    <li>The Rights holder has the right to refuse to change or delete Personal Data if such actions lead to:</li>
    </ol>
    </ol>
    <ul>
    <li>before violating the rules of this Policy;</li>
    <li>before violating the current legislation and/or Law;</li>
    <li>the nature of the Personal Data is evidence in any litigation that has arisen between the Rights holder and the User.</li>
    </ul>
    <p>&nbsp;</p>
    <ol start="7">
    <li><strong>TERMS AND PROCEDURE FOR STORAGE OF PERSONAL DATA</strong></li>
    <ol>
    <li>Storage of Personal Data is carried out independently by the Rights holder.</li>
    <li>Storage is carried out for the entire period necessary to achieve the stated purposes of Personal Data processing.</li>
    <li>The Rights holder undertakes to destroy or depersonalize the Personal Data immediately after achieving the goals of Personal Data processing.</li>
    <li>Use of the Site is intended for persons of any age who access it only with the prior consent of the processing of their Personal Data.</li>
    </ol>
    </ol>
    <p>&nbsp;</p>
    <ol start="8">
    <li><strong>PROCEDURE FOR PROTECTION OF PERSONAL DATA</strong></li>
    <ol>
    <li>Protecting the confidentiality of Personal Data is a primary and important task for the Rights holder. The Rights holder adheres to all necessary international standards, rules and recommendations on Personal Data protection.</li>
    <li>The Rights holder has introduced a number of technical and organizational methods aimed at protecting Personal Data from disclosure or unauthorized access to them by third parties.</li>
    </ol>
    </ol>
    <p>&nbsp;</p>
    <ol start="9">
    <li><strong>FINAL PROVISIONS</strong></li>
    <ol>
    <li>Users can read the terms of this Policy at the following link: <a href="https://imavirtuosos.com/privacy_eng">https://imavirtuosos.com/privacy_eng</a>.</li>
    <li>This Policy has also been drafted in English, for those Users who wish to familiarize themselves with the Policy but do not speak Ukrainian. In case of divergence between the text of the original (Ukrainian) and its translation into English, the Ukrainian language shall prevail. This version of the Policy has been in force since the publication of such Policy on the Site.</li>
    <li>This Policy may be changed unilaterally at the initiative of the Rights holder. The Rights holder shall not be liable to the User for changing the terms of this Policy without the permission and/or consent of the User.</li>
    <li>The User undertakes to regularly check the provisions of this Policy for possible changes or additions.</li>
    <li>This Policy is developed in accordance with the current legislation of Ukraine on Personal Data protection.</li>
    <li>Regardless of the measures taken by the Rights holder to protect the confidentiality of Personal Data received, the User is hereby considered to be duly aware that any transfer of Personal Data on the Internet cannot be guaranteed secure, and therefore the User makes such transfer at his own risk.</li>
    </ol>
    </ol></div>
  )
}

export default Privacy_eng