import React, {useState, useEffect} from 'react'

import LocalizedStrings from 'react-localization';

import Arrow_bottom from '../assets/arrow_bottom.svg'
import start_rock_group from '../assets/start_rock_group.svg'
import turning_planet from '../assets/Planet.png'
import meteor_start from '../assets/meteor_start.png'
import Up_button from '../assets/up_button.svg'

import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

let localization = new LocalizedStrings({
  en:{
    button:"Apply",
    description:"Ten-day summer music academy launched by innovative Dzialak & Söhne music academy for young musicians from all over the world",
    moreInfo:"Learn more",
  },
  ukr: {
    button:"Подати заявку",
    description:"Десятиденна літня музична академія, ініційована музичною академією «Dzialak&Söhne», для юних музикантів зі всього світу",
    moreInfo:"Дізнатись більше",
  }
 })




 const scrollToElement = (element_name) => {
  scroller.scrollTo(element_name, {
    duration: 800,
    smooth: true,
  })
}

const Start = ({ukrSelected}) => {

  ukrSelected ? 
    localization.setLanguage('ukr')
  :
    localization.setLanguage('en')
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        setScrollY(window.scrollY);
      };
      handleScroll();
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    
  return (
    <>
     <img src={turning_planet} className='start-planet'/>
      <img src={start_rock_group} className='start-rock-group' style={{marginTop: `${scrollY*0.20}px`, marginRight: `${scrollY*0.05}px`, transform: `rotate(${scrollY*0.01}deg)`}}/>
      <img src={meteor_start} className='meteor-start' style={{marginTop: `${scrollY*0.05}px`, marginLeft: `-${scrollY*0.05}px`}}/>
      <div className='start-container-wrap'>
        <div className='start-container'>
          <div className='main-title-wrap'>
                <div className='main-title-container-left'>
                  <div className='main-title-container-left-top'>
                    <div className='main-ima'>
                      <p>IMA.</p>
                    </div>
                    <div className='main-academy'>
                      <p>international music academy</p>
                    </div>
                  </div>
                  <div className='main-virtuosos'>
                    <p>VIRTUOSOS</p>
                  </div>

                </div>

                <div className='main-title-container-right'>
                    <div className='main-button-wrap'>
                      <div onClick={() => scrollToElement('applicationElement')} className='main-button'>
                        <p className='main-button-text'>{localization.button}</p>
                      </div>
                      <div className='main-button-shadow'></div>
                    </div>
                    <div className='main-text'>
                      <p>{localization.description}</p>
                    </div>
                </div>
          </div>

          <div className='main-shift-button-wrap'>
            <div onClick={() => scrollToElement('howElement')} className='main-shift-button'>
              <p>{localization.moreInfo}</p>
              <img src={Arrow_bottom}></img>
            </div>
          </div>
        </div>
      </div>
      <div className='up-container'>
        <p className='up-text'>Вгору</p>
        <img onClick={() => scrollToElement('startElement')} src={Up_button}></img>
      </div>
    </>
  )
}

export default Start