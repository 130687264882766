import React from 'react'

const UserAgreement_eng = () => {
  return (
    <div>
    <p><strong>USER AGREEMENT</strong></p>
<ol>
<li><strong>GENERAL PROVISIONS</strong></li>
<ol>
<li>This Agreement defines the rules and procedures for using the Site, the rights and obligations of Users, as well as regulates the behavior of Users when accessing the Site.</li>
<li>This Agreement is an accession agreement in the context of the norms of current Swiss legislation and international agreements, which excludes the possibility for the User to offer his terms of such an Agreement.</li>
<li>This Agreement is binding on both the Company and the User.</li>
<li>Acceptance of this Agreement will be considered the actions of the User, as a result of which such User gains access to the Site.</li>
<li>The Company reserves the right to unilaterally amend the terms of this Agreement and/or withdraw the offer at any time in its sole discretion without prior notice (notification) to Users. The new version of the Agreement shall enter into force upon its posting, unless otherwise provided by the terms of the new version of such Agreement. The User is obliged to follow the current version of the Agreement.</li>
<li>Continuation of use of the Site after any changes to the Agreement is unequivocal and sufficient confirmation of the User's agreement with the new terms of the Agreement.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li><strong>DEFINITIONS OF CONCEPTS</strong></li>
<ol>
<li><strong>"Site" </strong>means an information unit on the Internet, a resource of web pages (documents) that are linked by a common theme and linked to each other by links. The Site is registered with the Company and must be linked to a specific domain that is its address. This User Agreement is designed for the following site: <a href="https://www.imavirtuosos.com/">https://www.imavirtuosos.com/</a>.</li>
<li><strong>"User"</strong> means a legal or natural person who</li>
</ol>
</ol>
<ul>
<li>uses the Site and/or accesses its Content;</li>
<li>has agreed to comply with the rules of use of the Site set out in the text of this Agreement by placing a special symbol in the box provided on the Site.</li>
</ul>
<ol start="2">
<ol start="3">
<li><strong>"Company"</strong> - means a legal entity registered in accordance with the applicable laws of Switzerland, namely: <strong>Dzialak &amp; S&ouml;hne Musik-Akademie, Inh. J. Dzialak, </strong>Company code: CHE-116.166.121 from 03.11.2010, legal address: 8180, Switzerland, B&uuml;lach, str. Im Baumgarten, building 8, which is the owner of the Site and manages the Site.</li>
<li><strong>"Site Content"</strong> means all objects posted by the Company and / or third parties (with the Company's permission) on the Site, including design elements, text, graphics, illustrations, videos, programs, music, sounds, information, messages and any or other objects of similar purpose, their selections or combinations.</li>
<li><strong>"Site Software"</strong> means software developed by the Company (and/or third parties on behalf of the Company) for the Site, including, but not limited to, all software, scripts, codes (HTML codes), programs, etc.</li>
<li><strong>"Services" </strong>means the combined Content of the Site and the Software of the Site.</li>
<li><strong>"Project"</strong> means a set of information and procedures that will lead to the achievement of a specific goal. The Project should be understood as an educational project with the working title "International Music Academy of Virtuosos (IMA Virtuosos)" - a summer music academy for children of violinists, violists, pianists, cellists, flutists aged from 6 (six) to 24 (twenty-four) years old, training which is held as part of master classes by European teachers, known and recognized in the world of music. The learning process ends with a final concert and the presentation of diplomas.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="3">
<li><strong>USERS OF THE SITE</strong></li>
<ol>
<li>To use the Site, Users must not be restricted in their right of access to the Site and/or the Services based on a court decision that has entered into force or in cases provided by current legislation of Switzerland and/or the terms of this Agreement.</li>
<li>No registration is required to use the Site.</li>
<li>To use the Site as part of taking part in the Project, you need to complete the appropriate registration on the Site.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="4">
<li><strong>INTELLECTUAL PROPERTY</strong></li>
<ol>
<li>The Company own all intellectual property rights without exception, including intellectual property rights, all Site Content, Site Software, , as well as the Project. The Site Software, Site Content and Project, are protected by copyright in the manner prescribed by applicable law of Switzerland, as well as international treaties and conventions in the field of protection of intellectual property.</li>
<li>Users are prohibited from copying, reproducing, modifying, compiling, distributing, displaying in any form, publishing, downloading, transmitting, selling, selling or otherwise distributing or using the Site Content, Software of the Site and Project, except as expressly permitted by this Agreement or norms of the current legislation of Switzerland or with the written consent of the Company.</li>
<li>Nothing in the text of this Agreement may be construed as transferring to the User any exclusive rights to the Site Content (in whole and/or separately), the Site Software) and/or Project.</li>
<li>The Company has all the rights to trademarks (marks for goods and services), commercial (business) names, brands, logos registered in its name (hereinafter "Trademarks"). Such Trademarks are protected by the current legislation of Switzerland and nothing in the text of this Agreement may be construed as granting any license to the User to use such Trademarks.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="5">
<li><strong>PROCEDURE FOR WORKING WITH THE SITE</strong></li>
<ol>
<li>When using the Site, the User agrees to follow the following rules:</li>
</ol>
</ol>
<ul>
<li>comply with all obligations undertaken by the User in connection with the accession to this Agreement;</li>
<li>not to take any actions (with or without the use of automation tools) aimed at collecting any personal data of other Users;</li>
<li>not to take any action and not to assist third parties in taking actions aimed at undermining the work of the Site, including, but not limited to: downloading viruses or malicious code; take actions that may lead to the shutdown of the Site, disruption of the normal operation of the Site or the Software of the Site, or to the deterioration of the appearance of the Site and/or the Content of the Site.</li>
<li>not to engage in any other act that is illegal, fraudulent, discriminatory or misleading.</li>
</ul>
<p>&nbsp;</p>
<ol start="6">
<li><strong>ADVERTISING ON THE SITE</strong></li>
<ol>
<li>The Content of the Site may contain links to the official pages of the organizers and/or participants of the Project. The company does not assume any responsibility for:</li>
</ol>
</ol>
<ul>
<li>content of such official pages of the Project organizers and/or participants of the Project;</li>
<li>for any losses incurred by the User as a result of the latter's transition to such official pages of the Project organizer and/or participants of the Project.</li>
</ul>
<ol start="6">
<ol start="2">
<li>If you access another website through the information posted on the Site with a link, the Company cannot guarantee that such website is safe for the User and/or the User's personal computer (and/or any other device of the User). Nothing in this Agreement shall be construed as reassuring, encouraging, recommending or encouraging the User to link to such official sites, visit any third party websites, or attempt to purchase, use any third party goods/services.<span class="Apple-converted-space">&nbsp;</span></li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="7">
<li><strong>REGISTRATION ON THE SITE FOR PARTICIPATION IN THE PROJECT</strong></li>
<ol>
<li>The User is given the opportunity, only on his own initiative, to participate in the Project, for which the User must fill in the necessary data in the appropriate registration form posted on the Site, namely under the name "Apply".</li>
<li>After filling out the application, the Company contacts each User personally to provide complete and comprehensive information on the details of participation in the Project.</li>
<li>The User undertakes to provide reliable and accurate information about himself and his contact details for the possibility of the Company's feedback to the User.</li>
<li>By accepting this Agreement, the User consents to the collection and use of certain information about him in accordance with the provisions of the Swiss Federal Data Protection Act. Details regarding the use of such data by the Company are defined separately in the document "Policy of confidentiality and protection of personal data", which is posted on the Site at the link <a href="https://imavirtuosos.com/privacy_eng">https://imavirtuosos.com/privacy_eng</a>.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="8">
<li><strong>SUSPENSION OF ACCESS TO THE SITE</strong></li>
<ol>
<li>The Company has the right to terminate the use of the Site by the User at any time in the event of:</li>
</ol>
</ol>
<ul>
<li>violation by the User of the terms of this Agreement;</li>
<li>infringement of the intellectual property rights of the Company, other Users and/or third parties;</li>
<li>committing actions that are illegal, violate the rights and interests of the Company, other Users and/or third parties and / or undermine the operation of the Site and/or the possibility of using the Site by other Users;</li>
<li>The Services and/or the Site are used by the User in such a way that it may lead to legal liability of the Company in the future;</li>
<li>if such termination is required by the current legislation of Switzerland and/or the competent state body.</li>
</ul>
<ol start="8">
<ol start="2">
<li>The User is duly informed that the Company shall not be liable for any damages, lost profits, loss of business or personal reputation caused to the User's inability to access the Site and Services.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="9">
<li><strong>REQUEST FOR INFORMATION</strong></li>
<ol>
<li>If the User has any questions about the terms of this Agreement and/or the procedure/method of their implementation, the User may address such questions to the Company by sending an email to <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>.</li>
<li>Employees and representatives of the Company undertake to make every effort to respond to such a request within a reasonable period of time.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="10">
<li><strong>RESPONSIBILITY</strong></li>
<ol>
<li>Under no circumstances shall the Company and/or its representatives be liable to the User and/or to any third parties:</li>
</ol>
</ol>
<ul>
<li>for any indirect, incidental, unintentional damage, including lost profits and/or lost data, damage to honor, dignity and/or business reputation caused in connection with the use of the Site, Services and/or other materials to which the User and/or others have gained access through the Site, even if the Company has warned or indicated the possibility of such damage;</li>
<li>&bull; for actions and/or inaction of other Users;</li>
<li>in cases expressly provided by the terms of this Agreement and/or the norms of the current legislation of Switzerland.</li>
</ul>
<ol start="10">
<ol start="2">
<li>The Company's liability for anything related to the use of the Site and/or the Services is limited to the extent permitted by applicable law of Switzerland.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="11">
<li><strong>OTHER CONDITIONS</strong></li>
<ol>
<li>Users may read the terms of this Agreement at the following link: <a href="https://imavirtuosos.com/user_agreement_eng">https://imavirtuosos.com/user_agreement_eng</a>.</li>
<li>This Agreement is drawn up in the Ukrainian and English languages. In case of divergence between the text of the Ukrainian and English languages, the text of this Agreement in the Ukrainian language shall prevail over the content and interpretation.</li>
<li>This version of the Agreement is effective from the date of publication at the link specified in <strong>paragraph 11.1.</strong> of this Agreement.</li>
<li>All disputes and disagreements that arise during the implementation of the terms of this Agreement shall be resolved through negotiations between the Company and the User.</li>
<li>Complaints of the User are considered by the Company subject to their written submission and application to the Company within 1 (one) calendar month (including weekends, non-working days) from the date of reasonable grounds for filing a claim to <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>. In the event of a claim in violation of this deadline, the Company reserves the right to refuse to consider and/or satisfy such claim.</li>
<li>In case of impossibility to reach an agreement through negotiations, the dispute is transferred for settlement in court in accordance with the current legislation of Switzerland.</li>
<li>Any communication between the Company and the Users is carried out by e-mail using the registered e-mail addresses of the parties.</li>
<li>If, for any reason, any terms of this Agreement are held to be invalid, such invalidity shall not affect the validity and/or applicability of the remaining terms of this Agreement.</li>
<li>This Agreement and the relationship between the Company and the Users are governed by the applicable laws of Switzerland and international agreements. Issues not regulated by this Agreement shall be regulated by the current legislation of Switzerland and international agreements.</li>
<li>The Company does not undertake any obligation to notify users of future changes to the text of this Agreement. If after changes and/or additions in the text of this Agreement the User continues to use the Site, it means that he is aware of the changes and/or additions and accepted them in full without any objections.</li>
<li>Access to the Site and Services is provided to the User "as is". The Company does not promise or guarantee that the Services and/or the Site may or may not meet the needs, goals, expectations of the User, and therefore the Company does not guarantee any specific result or consequence of the User's use of the Site and/or Services.</li>
</ol>
</ol>
    </div>
  )
}

export default UserAgreement_eng