import LocalizedStrings from 'react-localization';
import header_logo from '../assets/header_logo.svg'
import * as React from 'react';
import {useState, useEffect} from 'react'
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


let localization = new LocalizedStrings({
  en:{
    menuBut1:"About",
    menuBut2:"Instructors",
    menuBut3:"Events schedule",
    menuBut4:"Application process",
    menuBut5:"Contacts",
  },
  ukr: {
    menuBut1:"Про проект",
    menuBut2:"Викладачі",
    menuBut3:"Розклад подій",
    menuBut4:"Як подати заявку",
    menuBut5:"Контакти",
  }
 })

 const scrollToElement = (element_name) => {
  scroller.scrollTo(element_name, {
    duration: 800,
    smooth: true,
  })
}

const Header = (props) => {
  
  props.ukrSelected ? 
  localization.setLanguage('ukr')
:
  localization.setLanguage('en')
  const [scrollY, setScrollY] = useState(0);
  let [blurPixels, setBlurPixels] = useState(0)
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollY > 50){
      setBlurPixels(3)
    } else {
      setBlurPixels(0)

    }
  }, [scrollY])
  
  console.log(props)




const sections = document.querySelectorAll("section");
const navBars = document.querySelectorAll(".header-nav-buttons p");


window.addEventListener("scroll", () => {
  let current = "";
  sections.forEach((section) => {
    const sectionTop = section.offsetTop;
    const sectionHeight = section.clientHeight;
    if (scrollY >= sectionTop - sectionHeight / 3) {
      current = section.getAttribute("id");
    }
  });
  console.log("there")
  console.log(current)

  navBars.forEach((p) => {
    p.classList.remove("active-slide");
    if (p.classList.contains(current)) {
      p.classList.add("active-slide");
    }
  });
});



  return (
      <header style={{ '-webkit-backdrop-filter': `blur(${blurPixels}px)`, 'backdrop-filter': `blur(${blurPixels}px)`}}>
      <div className='header-container'>
        <div className='header-logo'>
          <img src={header_logo}/>
        </div>

        <div className='header-tabs'>
          <div className='header-nav-buttons'>
            <p className='about-element' onClick={() => scrollToElement('aboutElement')}>{localization.menuBut1}</p>
            <p className='schedule-element' onClick={() => scrollToElement('scheduleElement')}>{localization.menuBut3}</p>
            <p className='instructors-element' onClick={() => scrollToElement('instructorsElement')}>{localization.menuBut2}</p>
            <p className='application-element'onClick={() => scrollToElement('howElement')}>{localization.menuBut4}</p>
          </div>

          <div className='header-change-language'>
            <div className='language-toggler'>
              { props.ukrSelected ?
                <>
                  <div className='select-english noselect' onClick={props.toggleLanguage} style={{backgroundColor: 'transparent' }} ><p style={{color: "white"}}>ENG</p></div>
                  <div className='select-ukrainian noselect' onClick={props.toggleLanguage} style={{backgroundColor: "white" }}><p style={{color: "black"}}>УКР</p></div> 
                </>
                :
                <>
                  <div className='select-english noselect' onClick={props.toggleLanguage} style={{backgroundColor: 'white' }} ><p style={{color: "black"}}>ENG</p></div>
                  <div className='select-ukrainian noselect' onClick={props.toggleLanguage} style={{backgroundColor: "transparent" }}><p style={{color: "white"}}>УКР</p></div> 
                </>
              }
            </div>
          </div>
          
        </div>

      </div>
    </header>
  )
}

export default Header
