import React from 'react'

const UserAgreement_ukr = () => {
  return (
    <div>
    <p>&nbsp;</p>
<p><strong>УГОДА КОРИСТУВАЧА</strong></p>
<p>&nbsp;</p>
<ol>
<li><strong>ЗАГАЛЬНІ ПОЛОЖЕННЯ</strong></li>
<ol>
<li>Ця Угода визначає правила та порядок використання Сайту, права та обов'язки Користувачів, а також регулює поведінку Користувачів при отриманні доступу до Сайту.</li>
<li>Ця Угода є договором приєднання в контексті норм чинного законодавства Швейцарії та міжнародних угод, що виключає можливість Користувача пропонувати свої умови такої Угоди.</li>
<li>Ця Угода є обов'язковою для виконання як Компанією, так і Користувачем.</li>
<li>Акцептом цієї Угоди вважатиметься вчинення Користувачем дій, внаслідок яких такий Користувач отримує доступ до Сайту.</li>
<li>Компанія залишає за собою право без попереднього попередження (повідомлення) Користувачів в односторонньому порядку вносити зміни до умов цієї Угоди та/або відкликати оферту в будь-який момент на власний розсуд. Нова редакція Угоди набирає чинності з моменту її розміщення, якщо інше не передбачено умовами нової редакції такої Угоди. Користувач зобов'язаний самостійно стежити за актуальною редакцією Угоди.</li>
<li>Продовження користування Сайтом після будь-яких змін Угоди є однозначним і достатнім підтвердженням згоди Користувача з новими умовами Угоди.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="2">
<li><strong>ВИЗНАЧЕННЯ ПОНЯТЬ</strong></li>
<ol>
<li><strong>"Сайт"</strong> означає інформаційну одиницю в Інтернеті, ресурс із веб-сторінок (документів), які об'єднані спільною темою та пов'язані один з одним за допомогою посилань. Сайт зареєстрований на Компанію та обов'язково прив'язаний до конкретного домену, що є його адресою. Ця Угода користувача розроблена для наступного сайту: <a href="https://www.imavirtuosos.com/">https://www.imavirtuosos.com/</a>.</li>
<li><strong>"Користувач" </strong>- означає юридичну або фізичну особу, яка</li>
</ol>
</ol>
<ul>
<li>користується Сайтом та/або отримала доступ до її Контенту;<span class="Apple-converted-space">&nbsp;</span></li>
<li>дала свою згоду на дотримання правил користування Сайтом, викладених у тексті цієї Угоди, шляхом проставлення спеціального символу у відведеному полі на Сайті.</li>
</ul>
<ol start="2">
<ol start="3">
<li><strong>"Компанія" </strong>&ndash; означає юридичну особу, зареєстровану відповідно до норм чинного законодавства Швейцарії, а саме: <strong>Dzialak &amp; S&ouml;hne Musik-Akademie, Inh. J. Dzialak</strong>, код Компанії CHE-116.166.121 від 03.11.2010 року, юридична адреса: 8180, Швейцарія, м. Бюлах (B&uuml;lach), вул. Im Baumgarten, буд. 8, яка є власником Сайту та керує Сайтом.</li>
<li><strong>"Контент сайту"</strong> &ndash; це всі об'єкти, розміщені Компанією та/або третіми особами (з дозволу Компанії) на Сайті, у тому числі елементи дизайну, текст, графічні зображення, ілюстрації, відео, програми, музика, звуки, інформація, повідомлення та/або інші об'єкти схожого призначення, їх добірки чи комбінації.</li>
<li><strong>"ПЗ сайту"</strong> означає програмне забезпечення, розроблене Компанією (та/або третіми особами за дорученням Компанії) для Сайту, включаючи, однак не обмежуючись, весь софт, скрипти, коди (HTML коди), програми тощо.</li>
<li><strong>"Послуги"</strong> означає сукупно Контент сайту, а також ПЗ сайту.</li>
<li><strong>"Проєкт"</strong> означає набір інформації та порядок дій, виконання яких призведе до досягнення певної мети. Під Проєктом слід розуміти освітній проект під робочою назвою<span class="Apple-converted-space">&nbsp; </span><strong>&laquo;International Music Academy of Virtuosos (IMA Virtuosos)&raquo;</strong> - літня музична академія<span class="Apple-converted-space">&nbsp; </span>для дітей скрипалів, альтистів, піаністів, віолончелістів, флейтистів віком від 6 (шести) до 24 (двадцяти чотирьох) рокі, навчання в якій проводиться в рамках проведення майстер-класів ід європейських викладачів, відомих та визнаних в музичному світі. Процес навчання завершується заключним концертом та врученням дипломів.<span class="Apple-converted-space">&nbsp;</span></li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="3">
<li><strong>КОРИСТУВАЧІ САЙТУ</strong></li>
<ol>
<li>Для використання Сайту Користувачі не повинні бути обмеженими у праві доступу до Сайту та/або Послуг на підставі рішення суду, який набув чинності, або у випадках, передбачених чинним законодавством Швейцарії та/або умовами цієї Угоди.</li>
<li>Для використання Сайту не потрібне проходження будь-якої реєстрації.</li>
<li>Для використання Сайту в рамках прийняття участі у Проєкті<span class="Apple-converted-space">&nbsp; </span>потрібне проходження відповідної реєстрації на Сайті.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="4">
<li><strong>ІНТЕЛЕКТУАЛЬНА ВЛАСНІСТЬ</strong></li>
<ol>
<li>Компанії належать всі без винятку права інтелектуальної власності, включаючи майнові права інтелектуальної власності, весь Контент сайту, ПЗ сайту, а також Проєкт. ПЗ сайту, Контент сайту та Проєкт захищені авторським правом у порядку, передбаченому нормами чинного законодавства Швейцарії, а також міжнародними договорами та конвенціями у галузі захисту об'єктів інтелектуальної власності.</li>
<li>Користувачам заборонено копіювати, відтворювати, модифікувати, компілювати, розповсюджувати, відображати в будь-якій формі, публікувати, завантажувати, передавати, продавати або іншим способом поширювати або використовувати Контент сайту, ПЗ сайту та Проєкт, крім випадків, коли такі дії прямо дозволені умовами цієї Угоди або нормами чинного законодавства Швейцарії або за письмовою згодою Компанії.</li>
<li>Ніщо в тексті цієї Угоди не може бути витлумачено як передача Користувачеві будь-яких виключних прав на Контент сайту (повністю та/або окремо), ПЗ сайту та/або Проєкт.</li>
<li>Компанія має всі права щодо торговельних марок (знаків для товарів та послуг), комерційних (бізнес) найменувань, брендів, логотипів, зареєстрованих на ім'я Компанії (далі "Торговельні марки"). Такі Торгові марки захищаються нормами чинного законодавства Швейцарії та ніщо в тексті цієї Угоди не може бути витлумачено як надання будь-якої ліцензії Користувачеві на використання таких Торговельних марок.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="5">
<li><strong>ПОРЯДОК РОБОТИ З САЙТОМ</strong></li>
<ol>
<li>Під час використання Сайту Користувач зобов'язується дотримуватись наступних правил:</li>
</ol>
</ol>
<ul>
<li>дотримуватися всіх зобов'язань, взятих на себе Користувачем у зв'язку з приєднанням до цієї Угоди;</li>
<li>не вчиняти будь-які дії (з використанням засобів автоматизації або без таких засобів), спрямовані на збирання будь-яких персональних даних інших Користувачів;</li>
<li>не вчиняти будь-яких дій та не допомагати третім особам у вчиненні дій, спрямованих на підрив роботи Сайту, включаючи, однак не обмежуючись: завантажувати віруси або шкідливий код; вчиняти дії, які можуть призвести до відключення Сайту, порушення нормальної роботи Сайту або ПЗ сайту, або до погіршення зовнішнього вигляду Сайту та/або Контенту Сайту.</li>
<li>не вчиняти будь-яких інших дій, які є незаконними, шахрайськими, дискримінаційними або такими, що вводять в оману.</li>
</ul>
<p>&nbsp;</p>
<ol start="6">
<li><strong>РОЗМІЩЕННЯ РЕКЛАМИ НА САЙТІ</strong></li>
<ol>
<li>Контент Сайту може містити посилання на офіційні сторінки організаторів та/або учасників Проєкту. Компанія не бере на себе будь-яку відповідальність за:</li>
</ol>
</ol>
<ul>
<li>зміст таких офіційних сторінок організаторів та/або учасників Проєкту;</li>
<li>за будь-які збитки, втрати, понесені Користувачем в результаті переходу останнім на такі офіційні сторінки організатора та/або учасника Проєкту.</li>
</ul>
<ol start="6">
<ol start="2">
<li>У разі переходу на інший веб-сайт через розміщену на Сайті інформацію із посиланням, Компанія не може гарантувати, що такий веб-сайт є безпечним для Користувача та/або персонального комп'ютера Користувача (та/або будь-якого іншого пристрою Користувача). Ніщо в тексті цієї Угоди не повинно бути витлумачено як запевнення, заохочення, рекомендація або спонукання Користувача скористатися посиланням на такі офіційні сторінки, відвідати будь-які веб-сайти третіх осіб, а також спробувати, придбати, скористатися будь-якими товарами/послугами третіх осіб.</li>
</ol>
</ol>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ol start="7">
<li><strong>РЕЄСТРАЦІЯ НА САЙТІ ДЛЯ УЧАСТІ У ПРОЄКТІ</strong></li>
<ol>
<li>Користувачеві надається можливість, лише за власною ініціативою, прийняти участь у Проєкті, для чого Користувач повинен заповнити необхідні дані у відповідній реєстраційній формі, розміщеній на Сайті, а саме під назвою &laquo;Подати заявку&raquo;.</li>
<li>Після заповнення заявки Компанія зв&rsquo;язується особиста із кожним Користувачем для надання повної і вичерпної інформації щодо деталей участі у Проєкті.</li>
<li>Користувач зобов'язується надати достовірну та точну інформацію про себе та свої контактні дані для можливості зворотного зв&rsquo;язку Компанії з Користувачем.</li>
<li>Приймаючи цю Угоду, Користувач дає згоду на збирання та використання певної інформації про нього відповідно до положень Федерального Закону Швейцарії &laquo;Про захист даних&raquo;. Деталі щодо використання таких даних Компанією визначено окремо в документі &laquo;Політика конфіденційності та захисту персональних даних&raquo;, яка розміщена на Сайті за посиланням <a href="https://imavirtuosos.com/privacy_ukr">https://imavirtuosos.com/privacy_ukr</a>.<span class="Apple-converted-space">&nbsp;</span></li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="8">
<li><strong>ПРИПИНЕННЯ ДОСТУПУ ДО САЙТУ</strong></li>
<ol>
<li>Компанія має право будь-якої миті припинити використання Сайту Користувачем у разі:</li>
</ol>
</ol>
<ul>
<li>порушення Користувачем умов цієї Угоди;<span class="Apple-converted-space">&nbsp;</span></li>
<li>порушення прав інтелектуальної власності Компанії, інших Користувачів та/або третіх осіб;<span class="Apple-converted-space">&nbsp;</span></li>
<li>вчинення дій, які є протизаконними, порушують права та інтереси Компанії, інших Користувачів та/або третіх осіб та/або підривають роботу Сайту та/або можливість використання Сайту іншими Користувачами;<span class="Apple-converted-space">&nbsp;</span></li>
<li>Послуги та/або Сайт використовується Користувачем таким чином, що це може спричинити юридичну відповідальність Компанії у майбутньому;<span class="Apple-converted-space">&nbsp;</span></li>
<li>якщо таке припинення вимагають норми чинного законодавства Швейцарії та/або компетентний державний орган.</li>
</ul>
<ol start="8">
<ol start="2">
<li>Користувач належним чином повідомлений, що Компанія не бере на себе відповідальність за будь-які збитки, упущену вигоду, втрату ділової або особистої репутації, заподіяні Користувачеві неможливістю доступу до Сайту та Послуг.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="9">
<li><strong>ЗАПИТ ДЛЯ ОТРИМАННЯ ІНФОРМАЦІЇ</strong></li>
<ol>
<li>Якщо у Користувача виникли питання щодо умов цієї Угоди та/або порядку/спосіб їх виконання, Користувач може адресувати такі питання Компанії надіславши листа на електронну пошту: <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>.</li>
<li>Співробітники та представники Компанії зобов'язуються докласти всіх можливих зусиль для відповіді на такий запит протягом розумного періоду часу.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="10">
<li><strong>ВІДПОВІДАЛЬНІСТЬ</strong></li>
<ol>
<li>Ні за яких обставинах Компанія та/або її представники не несуть відповідальність перед Користувачем та/або перед будь-якими третіми особами:</li>
</ol>
</ol>
<ul>
<li>за будь-яку непряму, випадкову, ненавмисну шкоду, включаючи упущену вигоду та/або втрачені дані, шкоду честі, гідності та/або діловій репутації, викликану у зв'язку з використанням Сайту, Послуг та/або інших матеріалів, до яких Користувач та/або інші особи отримали доступ за допомогою Сайту, навіть якщо Компанія попереджувала та/або вказувала на можливість такої шкоди;</li>
<li>за дії та/або бездіяльність інших Користувачів;</li>
<li>у випадках прямо передбачених умовами цієї Угоди та/або нормами чинного законодавства Швейцарії.</li>
</ul>
<ol start="10">
<ol start="2">
<li>Відповідальність Компанії за будь-що, пов'язане з використанням Сайту та/або Послуг, обмежується настільки, наскільки це допускається нормами чинного законодавства Швейцарії.</li>
</ol>
</ol>
<p>&nbsp;</p>
<ol start="11">
<li><strong>ІНШІ УМОВИ</strong></li>
<ol>
<li>Користувачі можуть ознайомитися з умовами цієї Угоди за наступним посиланням: <a href="https://imavirtuosos.com/user_agreement_ukr">https://imavirtuosos.com/user_agreement_ukr</a>.</li>
<li>Ця Угода складена українською та англійською мовами. У разі розходження тексту української та англійської мов, переважну юридичну силу щодо змісту та трактування має текст цієї Угоди на українській мові.</li>
<li>Ця редакція Угоди діє з моменту опублікування за посиланням, вказаним у п. 11.1. цієї Угоди.</li>
<li>Усі спори та розбіжності, що виникають під час виконання умов цієї Угоди вирішуються шляхом переговорів між Компанією та Користувачем.</li>
<li>Претензії Користувача розглядаються Компанією за умови їх викладення у письмовій формі та звернення до Компанії в межах 1 (одного) календарного місяця (враховуючи вихідні, неробочі дні) від дня виникнення обґрунтованих підстав для висування претензії на електронну пошту <a href="mailto:info.imavirtuosos@gmail.com">info.imavirtuosos@gmail.com</a>. У разі висунення претензії з порушенням зазначеного строку, Компанія залишає за собою право відмовити у розгляді та/або задоволенні такої претензії.<span class="Apple-converted-space">&nbsp;</span></li>
<li>У разі неможливості досягти згоди за допомогою переговорів спір передається для вирішення в судовому порядку відповідно до норм чинного законодавства Швейцарії.</li>
<li>Будь-яка комунікація між Компанією та Користувачами здійснюється електронною поштою з використанням зареєстрованих електронних адрес сторін.</li>
<li>Якщо внаслідок певної причини будь-які умови цієї Угоди будуть визнані недійсними, така недійсність не впливає на чинність та/або застосовність решти умов цієї Угоди.</li>
<li>Ця Угода та відносини між Компанією та Користувачами регулюються нормами чинного законодавства Швейцарії та міжнародних угод. Питання, не врегульовані цією Угодою регламентуються нормами чинного законодавства Швейцарії та міжнародних угод.</li>
<li>Компанія не бере на себе будь-який обов'язок щодо повідомлення Користувачів про майбутні зміни тексту цієї Угоди. Якщо після змін та/або доповнень у тексті цієї Угоди Користувач продовжує користуватися Сайтом, це означає, що він ознайомлений зі змінами та/або доповненнями та прийняв такі зміни та доповнення у повному обсязі без будь-яких заперечень.</li>
<li>Доступ до Сайту та Послуг надається Користувачеві "як є". Компанія не обіцяє та не гарантує, що Послуги та/або Сайт можуть підходити та/або не підходити потребам, цілям, очікуванням Користувача, та у зв'язку з чим Компанія не гарантує будь-якого специфічного результату чи наслідку внаслідок використання Користувачем Сайту та/або Послуг.</li>
</ol>
</ol>
    </div>
  )
}

export default UserAgreement_ukr