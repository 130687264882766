import React from 'react'


import LocalizedStrings from 'react-localization';
let localization = new LocalizedStrings({
  en:{
    when:"When?",
    where:"Where?",
    what:"What?",
    whatValue:"Ten-day graduation program",
    whatPS:"Free program of master-classes for everyone",
    description:"Summer music academy is a series of tet-a-tet lessons and open master classes, with the famous and acknowledged in the music world professors addressed to pianists, violinists, violists, chelists and flutists aged 6 to 24 years old. The learning process ends with the final show and diploma presentation. ",
    moreInfo:"The free attendance program is a series of master classes and lectures from famous musicians and activists. Anyone willing can become a participant regardless of age. The order and number of visits are voluntary. ",
  },
  ukr: {
    when:"Коли?",
    where:"Де?",
    what:"Що?",
    whatValue:"Десятиденна програма з випускним",
    whatPS:"Вільна програма майстер-класів для всіх охочих",
    description:"Літня музична академія - це комплекс один-на-один уроків та відкритих майстер-класів з викладачами, відомими та визнаними в музичному світі, для скрипалів, альтистів, піаністів, віолончелістів, флейтистів від 6 до 24 років. Процес навчання завершується заключним концертом та видачею дипломів.",
    moreInfo:"Вільна програма - серія майстер-класів та лекцій від відомих музикантів та діячів. Учасниками можуть стати всі бажаючі, незалежно від віку, порядковість та кількість відвідувань неважлива. ",
  }
 })



const Schedule = ({ukrSelected}) => {

  ukrSelected ? 
    localization.setLanguage('ukr')
  :
    localization.setLanguage('en')

  return (
    <>
      <section id="schedule-element">

      <div className='schedule-main-container-wrap'>
        <div className='schedule-main-container'>
          <hr className='schedule-large-line'></hr>

          <div className='schedule-middle'>
            <div className='schedule-middle-left'> 
              <div className='schedule-question-first'>
                <p>{localization.when}</p>
              </div>
              <div className='schedule-title-first'>
                <p>25 June — 05 July</p>
              </div>

              <hr className='schedule-medium-line'></hr>

              <div className='schedule-question-second'>
                <p>{localization.where}</p>
              </div>
              <div className='schedule-title-second'>
                <p>Zurich, Switzerland</p>
              </div>
            </div>

            <div className='schedule-middle-right'> 
              <div className='schedule-text-top'>
                <p>{localization.description}</p>
              </div>
              <div className='schedule-text-bottom'>
                <p>{localization.moreInfo}</p>
              </div>    
            </div>
            
          </div>

          <hr className='schedule-large-line'></hr>

          <div className='schedule-question-third'>
            <p>{localization.what}</p>
          </div>
          <div className='schedule-title-third'>
            <p>{localization.whatValue}</p>
          </div>
          <div className='schedule-subtitle-third'>
            <p>{localization.whatPS}</p>
          </div>

          <hr className='schedule-large-line'></hr>

          <div className='schedule-bottom-mobile'> 
              <div className='schedule-text-top'>
                <p>{localization.description}</p>
              </div>
              <div className='schedule-text-bottom'>
                <p>{localization.moreInfo}</p>
              </div>    
            </div>

        </div>
      </div>
      </section>
    </>
  )
}

export default Schedule