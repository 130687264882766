import React from 'react'
import header_logo from '../assets/header_logo.svg'

import logo_evdev from '../assets/logo_website.png'
import LocalizedStrings from 'react-localization';


let localization = new LocalizedStrings({
  en:{
    policy:"Privacy policy",
    teachersInfo:"Download information about instructors",
    otherInfo:"Download other information",
  },
  ukr: {
    policy:"Політика конфіденційності",
    teachersInfo:"Скачати інформацію про викладачів ",
    otherInfo:"Скачати іншу інформацію",
  }
 })

const Bottomer = ({ukrSelected}) => {

  ukrSelected ? 
    localization.setLanguage('ukr')
  :
    localization.setLanguage('en')

  return (
    <>
      <div className='bottom-row-wrap'>
        <div className='bottom-row'> 
            <div className='bottom-copyright'>
              <p>© ima.virtuosos, 2022</p>
            </div>
            {/* <div className='bottom-files'>
                <div className='bottom-file-link'>
                  <p>{localization.policy}</p>
                </div>
                <div className='bottom-file-link'>
                  <p>{localization.teachersInfo}</p>
                </div>
                <div className='bottom-file-link'>
                  <p>{localization.otherInfo}</p>
                </div>
            </div> */}
            <a href='https://www.figma.com/proto/2UCIavg8otRVwnSMfphiFx/EVDEV?node-id=8%3A366&scaling=scale-down-width&page-id=0%3A1' className='evdev-section'>
              <p>developed by</p>
              <a href='https://www.figma.com/proto/2UCIavg8otRVwnSMfphiFx/EVDEV?node-id=8%3A366&scaling=scale-down-width&page-id=0%3A1'><img src={logo_evdev}/></a>
            </a>
            <div className='bottom-logo'>
              <img src={header_logo}/>
            </div>
        </div>
      </div>
    </>
  )
}

export default Bottomer