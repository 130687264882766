import React, {useEffect, useState} from 'react'

import LocalizedStrings from 'react-localization';
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Person1 from '../assets/person1.svg'
import Person2 from '../assets/person2.svg'
import Person3 from '../assets/person3.svg'
import File_download from '../assets/file_download.svg'
import Arrow_left from '../assets/arrow_left.svg'
import Arrow_right from '../assets/arrow_right.svg'
import group_meteors_team from '../assets/group_meteors_team.png'
import Team_member from './TeamMember.jsx'
import Andrii from '../assets/Andrii.JPG'
import Leh from '../assets/Leh.JPG'
import Bartek from '../assets/Bartek.JPG'
import Rudolf from '../assets/Rudolf.JPG'
import Anna from '../assets/Anna_new.jpeg'
import Yakub from '../assets/Yakub_new.jpeg'
import Bogdana from '../assets/Bogdana_new.jpeg'
import Maria from '../assets/Maria_new.jpeg'
import Kostia from '../assets/Kostia_new.jpeg'




let localization = new LocalizedStrings({
  en:{
    title:"Our Instructors",
    subtitle:"The guidance and mentorship in mastering music skills will be world-famous instructors. ",
    moreInfo:"For the full information about the instructors, please download the file below",
    moreInfoFile:"Learn more",
  },
  ukr: {
    title:"Команда",
    subtitle:"Провідниками та наставниками у вдосконаленні музичних навичок будуть відомі в музичному світі викладачі.",
    moreInfo:"Повну інформацію про кожного з викладачів ви можете отримати, завантаживши файл нижче",
    moreInfoFile:"Скачати повну інформацію про викладачів",
  }
 })


  let team = [
    {
        'photo': Anna,
        'name_ukr': 'Анна Савицька',
        'name_en': 'Anna Savytska',
        'instrument_ukr': 'Скрипка',
        'instrument_en': 'Violin',
        'bio_ukr': 'Відома у світі скрипалька, учасник та співзасновник дуету Innovation Duo, ідейниця та натхненниця академії ІМА.',
        'bio_en': 'The world-famous violinist, member, and co-founder of the duo Innovation Duo, the inspirer of the IMA Academy.',
        'ukr': false
    },
  
    {
        'photo': Yakub,
        'name_ukr': 'Якуб Дзялак',
        'name_en': 'Jakub Dzialak',
        'instrument_ukr': 'Скрипка',
        'instrument_en': 'Violin',
        'bio_ukr': 'Успішний скрипаль, учасник та співзасновник дуету Innovation Duo, засновник академії "Dzialak&Söhne"',
        'bio_en': 'Successful violinist, member and co-founder of the duo Innovation Duo, founder of the academy "Dzialak & Söhne".',
        'ukr': false
    },
    {
      'photo': Rudolf,
      'name_ukr': 'Рудольф Кульман',
      'name_en': 'Rudolf Koelman',
      'instrument_ukr': 'Скрипка',
      'instrument_en': 'Violin',
      'bio_ukr': 'Скрипаль, учень Яші Хейцефа, видатний викладач та журі міжнародних скрипкових конкурсів.',
      'bio_en': 'Violinist, student of Jascha Heifetz, outstanding teacher, and jury of international violin contests.',
      'ukr': false
    },
    {
      'photo': Bartek,
      'name_ukr': 'Бартек Нізьол',
      'name_en': 'Bartek Niziol',
      'instrument_ukr': 'Скрипка',
      'instrument_en': 'Violin',
      'bio_ukr': 'Скрипаль, перший концертмейстер філармонії Цюріха, викладач у Бернському університеті мистецтв.',
      'bio_en': 'Violinist, the first concertmaster of the Zurich Opera, and a lecturer at the Bern University of the Arts.',
      'ukr': false
    },
    {
      'photo': Andrii,
      'name_ukr': 'Андрій Драган',
      'name_en': 'Andriy Dragan',
      'instrument_ukr': 'Фортепіано',
      'instrument_en': 'Piano',
      'bio_ukr': 'Відомий концертуючий піаніст, учасник міжнародних фестивалів та призер музичних конкурсів.',
      'bio_en': 'Well-known concert pianist, a participant in international festivals, and winner of music contests.',
      'ukr': false
    },
    {
      'photo': Leh,
      'name_ukr': 'Лех Ушинський',
      'name_en': 'Lech Uszynski',
      'instrument_ukr': 'Альт',
      'instrument_en': 'Viola',
      'bio_ukr': 'Один з найбільш затребуваних альтів сьогодення. Концертуючий музикант, гість престижних фестивалів.',
      'bio_en': 'One of the most in-demand violists today. Concert musician, guest of prestigious festivals.',
      'ukr': false
    },
    {
      'photo': Bogdana,
      'name_ukr': 'Богдана Півненко',
      'name_en': 'Bogdana Pivnenko',
      'instrument_ukr': 'Скрипка',
      'instrument_en': 'Violin',
      'bio_ukr': 'Народна артистка України, лауреат міжнародних конкурсів, завідувач кафедри скрипки музичної академії.',
      'bio_en': 'Peopleʼs Artist of Ukraine, winner of international contests, head of the violin department of the Music Academy.',
      'ukr': false
    },
    {
      'photo': Kostia,
      'name_ukr': 'Костянтин Товстуха',
      'name_en': 'Kostiantyn Tovstukha',
      'instrument_ukr': 'Фортепіано',
      'instrument_en': 'Piano',
      'bio_ukr': 'Піаніст, володар багатьох міжнародних премій. Концертуючий музикант з широким репертуаром.',
      'bio_en': 'Pianist, winner of multiple international awards. Concert musician with a broad repertoire.',
      'ukr': false
    },
    {
      'photo': Maria,
      'name_ukr': 'Марія Берегова',
      'name_en': 'Maria Beregova',
      'instrument_ukr': 'Флейта',
      'instrument_en': 'Flute',
      'bio_ukr': 'Флейтистка, переможець всеукраїнських та міжнародних конкурсів, солістка оркестрів',
      'bio_en': 'Flutist, winner of national and international contests, soloist of orchestras.',
      'ukr': false
    },  
  
]

 var sliderSettings = {
  infinite: true,
  draggable: true,
  rtl: false,
  speed: 500,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  arrows: false,

  initialSlide: 2,
  marginLeft: '-250px',
};

const Teachers = ({ukrSelected}) => {
  const slider = React.useRef(null);

  ukrSelected ? 
    localization.setLanguage('ukr')
  :
    localization.setLanguage('en')

  for (const i in team) {
    ukrSelected ? 
      team[i].ukr = true
    :
      team[i].ukr = false
  }

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        setScrollY(window.scrollY);
      };
      handleScroll();
  
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);


  return (
    <>  
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
      <div className='desktopVersion'>
        <img src={group_meteors_team} className='animation-absolute group-meteors-team' style={{marginTop: `${-470 + scrollY*0.1}px`, marginRight: `${-100+scrollY*0.04}px`}}/>
      </div>
      {/* <div className='mobileVersion'>
        <img src={group_meteors_team} className='animation-absolute group-meteors-team' style={{marginTop: `${-470 + scrollY*0.1}px`}}/>
      </div> */}
      <section id="instructors-element">

      <div className='team-container-wrap'> 
        <div className='team-container'>
          <div className='team-title'>
            <p>{localization.title}</p>
          </div>
          <div className='team-top'>
            <div className='team-description'>
              <p>{localization.subtitle}</p>
            </div>
            <div className='team-buttons-row'>
              <button className='team-button' onClick={() => slider?.current?.slickPrev()}>
                <img src={Arrow_left}></img>
              </button>
              <button className='team-button'onClick={() => slider?.current?.slickNext()}>
                <img src={Arrow_right}></img>
              </button>
            </div>
          </div>
          <div className='team-list'>
              <div className='blure-block'></div>
              <Slider ref={slider} {...sliderSettings}>
                  {team.map((person) => (
                        <Team_member person={person} ukr={ukrSelected}/>
                    ))}
              </Slider>
          </div>




          <div className='team-list-mobile'>
          {team.map((person) => (
                        <Team_member person={person}/>
                    ))}
            </div>




          <div className='team-info' style={{display: 'none'}}>
            <div className='team-info-text'>
              <p>{localization.moreInfo}</p>
            </div>
            <div className='team-info-download' style={{display: 'none'}}>
              <img src={File_download}></img>
              <p>{localization.moreInfoFile}</p>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  )
}

export default Teachers