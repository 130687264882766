import React, { useContext } from 'react'
import { createContext, useState, useEffect } from 'react'
import { slide as Menu } from 'react-burger-menu'

import LocalizedStrings from 'react-localization';
import {Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import header_logo from '../assets/header_logo.svg'



let localization = new LocalizedStrings({
  en:{
    menuBut1:"About",
    menuBut2:"Professors",
    menuBut3:"Schedule",
    menuBut4:"Application process",
    menuBut5:"Contacts",
  },
  ukr: {
    menuBut1:"Про проект",
    menuBut2:"Викладачі",
    menuBut3:"Розклад",
    menuBut4:"Подати заявку",
    menuBut5:"Контакти",
  }
 })

 const scrollToElement = (element_name) => {
  scroller.scrollTo(element_name, {
    duration: 800,
    smooth: true,
  })
}



const MenuMobile = (props) => {
  const [isOpen, setOpen] = useState(false)
  const [burgerBar1, setBurgerBar1] = useState()
  const [burgerBar2, setBurgerBar2] = useState()
  const [burgerBar3, setBurgerBar3] = useState()


  var styles = {
    bmBurgerButton: {
      // position: 'fixed',
      display :'none',
      width: '36px',
      height: '30px',
      right: '30px',
      top: '30px',
      zIndex: '900'
    //   boxShadow: '1px 0px 5px black',
    },
    bmBurgerBars: {
      background: '#594AFF',
      borderRadius: '20px',
    //   boxShadow: '1px 0px 2px rgba(0, 0, 0, 0.25)',
      transition: '0.2s',
    },
    bmBurgerBarsHover: {
      background: '#a90000'
    },
    // bmCrossButton: {
    //   height: '50px',
    //   width: '50px',
      
    // },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: 'none',
      // padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
      overflow: 'hidden'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      // padding: '0.8em'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'none',
      '-webkit-backdrop-filter': 'blur(4px)',
      'backdrop-filter': 'blur(4px)',
      height: '100%',
    },
    bmCross: {
        display: 'none'
    },
    bmCrossButton: {
        // marginTop:'30px',
          height: '40px',
          width: '40px',
          borderRadius: '100px'
    }
  }
  // const handleIsOpen = () => {
  //   updateIsOpen(!isOpen)
  // }
  
  const updateIsOpen = () => {
    setOpen(!isOpen)
}
  const toggleSideBar = () => {
    updateIsOpen()
    if (!isOpen){
      setBurgerBar2({display: 'none'})
      setBurgerBar1({transform: "rotate(45deg)", marginTop: '12px'})
      setBurgerBar3({transform: "rotate(-45deg)", marginTop: '-12px'})
    } else {
      setBurgerBar2({})
      setBurgerBar1({})
      setBurgerBar3({})

    }
  }

  const closeSideBar = () => {
    updateIsOpen()
  }
      const SideBarLinks = ({ closeSideBar }) => {
        return (
          <>
          <div className='menubuttons-mobile'> 
            <p onClick={() => scrollToElement('aboutElement')}>{localization.menuBut1}</p>
            <p onClick={() => scrollToElement('instructorsElement')}>{localization.menuBut2}</p>
            <p onClick={() => scrollToElement('scheduleElement')}>{localization.menuBut3}</p>
            <p onClick={() => scrollToElement('applicationElement')}>{localization.menuBut4}</p>
          </div>
          </>
        )
      }

      props.ukrSelected ? 
        localization.setLanguage('ukr')
      :
        localization.setLanguage('en')

    return (
      <div>
        <div className='mobileV'>
            <Menu 
            styles={ styles } 
            isOpen={isOpen}
            onOpen={updateIsOpen}
            onClose={updateIsOpen}
             >
            
        <SideBarLinks closeSideBar={closeSideBar} />

            </Menu>
        </div>
        <div></div>
        <div className='header-mobile-flex' style={{position: 'fixed', zIndex: '10000', height: '100px', width: '70vw', justifyContent: 'space-between', alignItems: 'center', display: 'flex'}}>

          <div className='header-mobile-logo mobileVersion' style={{ marginLeft: '5%'}}>
            <img src={header_logo}/>   
          </div>
        
          <div className="bm-burger-button1" style={{zIndex: '1200'}} onClick={toggleSideBar}>
            {/* <button id="react-burger-menu-btn" style="position: absolute; left: 0px; top: 0px; z-index: 1; width: 100%; height: 100%; margin: 0px; padding: 0px; border: none; font-size: 0px; background-color: transparent; cursor: pointer;">Open Menu</button> */}
            <span>
              <span className="bm-burger-bars1" style={burgerBar1}></span>
              <span className="bm-burger-bars2" style={burgerBar2}></span>
              <span className="bm-burger-bars3" style={burgerBar3}></span>
            </span>
          </div>
          <div className='header-change-language mobileVersion'>
              <div className='language-toggler'>
                { props.ukrSelected ?
                  <>
                    <div className='select-english noselect' onClick={props.toggleLanguage} style={{backgroundColor: 'transparent' }} ><p style={{color: "white"}}>ENG</p></div>
                    <div className='select-ukrainian noselect' onClick={props.toggleLanguage} style={{backgroundColor: "white" }}><p style={{color: "black"}}>УКР</p></div> 
                  </>
                  :
                  <>
                    <div className='select-english noselect' onClick={props.toggleLanguage} style={{backgroundColor: 'white' }} ><p style={{color: "black"}}>ENG</p></div>
                    <div className='select-ukrainian noselect' onClick={props.toggleLanguage} style={{backgroundColor: "transparent" }}><p style={{color: "white"}}>УКР</p></div> 
                  </>
                }
              </div>
            </div>
            
            {/* <div className='mobile-blur-background'>
                
            </div> */}

        </div>
      </div>
        
    )
}

export default MenuMobile
